import { useContext } from "react";
import { CompanyContext } from "@/components/CompanyList/CompanyContext";
import { pathOr } from "ramda";

import { CouponDiscountIcon } from "../../../client/assets/icons/bestbanks/CouponDiscountIcon";
import { DollarIcon } from "../../../client/assets/icons/bestbanks/DollarIcon";
import { CheckIcon } from "../../../client/assets/icons/common/CheckIcon";
import { RightArrowIcon } from "../../../client/assets/icons/common/RightArrowIcon";
import BaseBenefitsList from "../../BaseComponents/BaseBenefitsList";
import { BaseCompanyLogo } from "../../BaseComponents/BaseCompanyLogo";
import BaseCompanyName from "../../BaseComponents/BaseCompanyName";
import { BaseCoupon } from "../../BaseComponents/BaseCoupon";
import { BaseFeaturedCompanyHeader } from "../../BaseComponents/BaseFeaturedCompanyHeader";
import BaseGridRatingHorizontal from "../../BaseComponents/BaseGridRatingHorizontal";
import BaseLinkWrapper from "../../BaseComponents/BaseLinkWrapper";
import BaseProductDescription from "../../BaseComponents/BaseProductDescription";
import BaseServiceFlag from "../../BaseComponents/BaseServiceFlag";
import { BaseSocialProof } from "../../BaseComponents/BaseSocialProof";
import { BaseVisitLink } from "../../BaseComponents/BaseVisitLink";
import CompanyApy from "./CompanyApy";
import { getStyles } from "./styles";

export const BestBanksCompany = () => {
	const { company, companyIndex, isFeaturedCompany } =
		useContext(CompanyContext);
	const featuredBenefit = pathOr(null, ["fields", "featuredBenefit"], company);
	const styles = getStyles({ companyIndex });

	return (
		<BaseLinkWrapper
			className={`tw-block tw-overflow-hidden tw-rounded-[12px] tw-bg-white tw-transition hover:tw-shadow-[0px_8px_32px_0px_rgba(0,0,0,0.08),0px_2px_20px_0px_rgba(0,0,0,0.04)] ${
				isFeaturedCompany
					? "tw-mb-8 tw-mt-6 tw-border tw-border-[#059669] lg:tw-mb-16 lg:tw-border-2"
					: "tw-mb-4 lg:tw-mb-6"
			}`}
		>
			<BaseFeaturedCompanyHeader
				icon={
					<img
						width={48}
						height={48}
						src="/react-images/bestbanks/medalImage.png"
						className="tw-size-full tw-w-full tw-object-contain"
					/>
				}
				{...styles.featuredCompanyHeader}
			/>
			<BaseCoupon {...styles.coupon} icon={<CouponDiscountIcon />} />
			<div className="tw-relative">
				<BaseServiceFlag classNames={styles.serviceFlag} />

				<div className="tw-mx-auto tw-flex tw-max-w-[400px] tw-flex-col tw-flex-wrap tw-gap-4 tw-p-4 lg:tw-max-w-none lg:tw-flex-row lg:tw-gap-0 lg:tw-p-6">
					<div className="tw-flex tw-w-full tw-justify-center lg:tw-max-w-[21.28%] lg:tw-shrink-0 lg:tw-grow-0 lg:tw-basis-[21.28%] lg:tw-pl-9 lg:tw-pr-[30px]">
						<div className="tw-flex tw-w-40 tw-flex-col tw-justify-center tw-gap-2 lg:tw-gap-4">
							<BaseCompanyLogo classNames={styles.companyLogo} />
							<BaseGridRatingHorizontal classNames={styles.rating} />
						</div>
					</div>

					<div className="tw-flex tw-w-full tw-max-w-full lg:tw-max-w-[59.7%] lg:tw-shrink-0 lg:tw-grow-0 lg:tw-basis-[59.7%] lg:tw-pl-[30px] lg:tw-pr-[22px]">
						<div className="tw-flex tw-w-full tw-flex-col">
							<BaseCompanyName className="tw-mb-2 tw-text-xl tw-font-bold tw-tracking-[-0.2px] tw-text-[#000F10] lg:tw-mb-0 lg:tw-text-2xl lg:tw-leading-8 lg:tw-tracking-[-0.48px]" />
							<BaseProductDescription className="tw-mb-2 tw-text-base tw-leading-6 tw-text-[#364445] lg:tw-mb-0 lg:tw-text-xl lg:tw-leading-8 lg:tw-tracking-[-0.4px]" />
							{featuredBenefit && (
								<div className="tw-mb-2 lg:tw-mb-0 lg:tw-mt-2">
									<div className="tw-inline-flex tw-rounded-full tw-bg-[#000F10] tw-py-1.5 tw-pl-1.5 tw-pr-3">
										<DollarIcon
											className="tw-text-black"
											bgColor="tw-text-white"
										/>
										<span className="tw-ml-1.5 tw-text-xs tw-font-bold tw-leading-4 tw-text-white">
											{featuredBenefit}
										</span>
									</div>
								</div>
							)}
							<CompanyApy />
							<BaseBenefitsList
								classNames={styles.benefits}
								icon={<CheckIcon className="tw-mt-1 tw-text-base" />}
							/>
						</div>
					</div>

					<div className="tw-flex tw-w-full tw-max-w-full lg:tw-max-w-[19.02%] lg:tw-shrink-0 lg:tw-grow-0 lg:tw-basis-[19.09%] lg:tw-pl-[22px]">
						<div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center">
							{companyIndex === 1 ? (
								<BaseSocialProof {...styles.socialProof} />
							) : null}
							<BaseVisitLink {...styles.visitLink} icon={<RightArrowIcon />}>
								View Offers
							</BaseVisitLink>
						</div>
					</div>
				</div>
			</div>
		</BaseLinkWrapper>
	);
};

export default BestBanksCompany;
