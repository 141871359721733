import OutboundLink from "@/components/general/OutboundLink";
import { FVY_DOMAIN, IR_DOMAIN, SITE } from "@/config/constants";
import { SOLAR_PATH } from "@/config/site/favy";
import * as irConstants from "@/config/site/insuranceranked";
import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { pathOr, prop } from "ramda";
import { useSelector } from "react-redux";
import tw, { css } from "twin.macro";

import withOutboundPaths from "../../HighOrderComponents/withOutboundPaths";

const styles = {
	legacy: {
		phoneLinkWrapper: css`
			width: 100%;
			max-width: 300px;
			text-align: center;
			position: relative;
			padding-left: 40px;
			text-align: left;
			margin-bottom: 25px;
			margin-top: 0;

			@media (max-width: 767px) {
				float: left;
				width: auto;
				background-color: #fff;
				border-radius: 3px;
				border: 1px solid #d6dbdf;
				padding: 15px 10px 13px 15px;
				margin-bottom: 0;
				margin-right: 15px;
				height: 52px;
			}

			@media (max-width: 400px) {
				margin-right: 0;
				margin-bottom: 10px;
			}

			a {
				color: #1d8ce0;
				font-size: 14px;
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 0.025em;
				position: relative;
				text-align: left;
				margin-bottom: 25px;
				margin-top: 0;
				width: 100%;
				max-width: 300px;
			}

			:before {
				position: absolute;
				content: "";
				background-image: url("/react-images/common/phone.svg");
				width: 34px;
				height: 34px;
				left: 0;
				top: 5px;
			}

			@media (max-width: 767px) {
				:before {
					width: 24px;
					height: 24px;
					left: 5px;
					top: 15px;
					background-size: 30px 20px;
					background-repeat: no-repeat;
				}
			}
		`,

		helpText: css`
			color: #333742;
			text-transform: none;
			font-size: 1rem;
			font-weight: 500;
			letter-spacing: -0.05em;
			margin-right: 0;
			font-family: AvenirNextLTPro-Demi;
			line-height: 1.1;

			@media (max-width: 1199px) {
				margin-right: 0;
			}

			@media (max-width: 767px) {
				display: none;
			}
		`,

		phoneSpan: css`
			position: relative;
			text-align: left;
			margin-bottom: 25px;
			width: 100%;
			max-width: 300px;
			margin-top: 0;
			font-weight: 500;
			line-height: 1.1;
			margin-right: 10px;
			color: #1d8ce0;
			font-family: AvenirNextLTPro-Demi;
			font-size: 1.5rem;
			letter-spacing: -1px;

			@media (max-width: 1199px) {
				margin-right: 0;
			}

			@media (max-width: 767px) {
				font-size: 1.2rem;
				padding-left: 20px;
				height: 20px;
				bottom: 20px;
			}
		`,
	},
	revamped: {
		phoneLinkWrapper: css`
			width: 100%;
			max-width: 300px;
			text-align: center;
			position: relative;
			text-align: left;
			margin-top: 0;
			width: 175px;
			background-color: #fff;
			border-radius: 3px;
			background-color: #6ac54e;
			padding: 13px 10px 13px 15px;
			margin: 0 auto 10px;
			height: 46px;

			a {
				color: #1d8ce0;
				font-size: 14px;
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 0.025em;
				position: relative;
				text-align: left;
				margin-bottom: 25px;
				margin-top: 0;
				width: 100%;
				max-width: 300px;
			}

			:before {
				position: absolute;
				content: "";
				background-image: url("/react-images/common/whitePhone.svg");
				width: 24px;
				height: 24px;
				left: 5px;
				top: 15px;
				background-size: 30px 20px;
				background-repeat: no-repeat;
			}
		`,

		helpText: css`
			display: none;
		`,

		phoneSpan: css`
			position: relative;
			text-align: left;
			margin-bottom: 25px;
			width: 100%;
			max-width: 300px;
			margin-top: 0;
			font-weight: 500;
			line-height: 1.1;
			margin-right: 0;
			color: #ffffff;
			font-family: AvenirNextLTPro-Demi;
			font-size: 1.2rem;
			letter-spacing: -1px;
			padding-left: 20px;
			height: 20px;
			bottom: 20px;
		`,

		alternateRevampedPhoneStyling: {
			phoneLinkWrapper: {
				base: css`
					:before {
						display: none;
					}
					all: initial;
					display: flex;
					width: 175px;
					line-height: 1;
					a {
						margin-bottom: 0px;
						color: unset;
						letter-spacing: unset;
						font-size: 16px;
					}
					background-color: inherit;

					@media (min-width: 991px) {
						margin-bottom: 10px;
					}
				`,
				[SOLAR_PATH]: css`
					@media (max-width: 500px) {
						margin-bottom: 10px;
					}
				`,
			},
			outboundLink: {
				base: [
					css`
						font-family: Montserrat;
						margin-bottom: 0px;
					`,
					tw`inline-flex items-center justify-center w-full text-sm font-bold py-3 my-1 rounded bg-blue-200 text-black hover:bg-blue-300`,
				],
				[SOLAR_PATH]: css`
					${tw`flex justify-center leading-6 p-2 rounded-full bg-blue-700 bg-no-repeat duration-100 hover:bg-blue-700`},
				`,
			},
			phoneSpan: tw`hidden`,
			helpText: tw`hidden`,
		},
	},
};

const phoneLinkChildComponent = (phoneNumber, phoneNumberStyles) => (
	<>
		<figure className="tw-mb-auto tw-mr-2 tw-mt-auto tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center">
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					cx="12"
					cy="12"
					r="11"
					fill="white"
					stroke="#283033"
					strokeWidth="2"
				/>
				<g>
					<path
						d="M17.3141 13.8002L14.9614 12.7562C14.7287 12.6533 14.4685 12.63 14.2211 12.6899C13.9738 12.7498 13.7532 12.8897 13.5934 13.0877L12.8831 13.9765C11.7321 13.2443 10.7559 12.2683 10.0234 11.1175L10.9114 10.408C11.1101 10.2484 11.2506 10.0276 11.3108 9.77987C11.371 9.53218 11.3477 9.27153 11.2444 9.03849L10.1996 6.68574C10.0886 6.43584 9.89254 6.23347 9.64627 6.11466C9.4 5.99585 9.11955 5.96832 8.85488 6.03699L6.86588 6.55374C6.59147 6.62542 6.35289 6.79525 6.19531 7.03106C6.03773 7.26688 5.97211 7.55229 6.01088 7.83324C6.37921 10.3961 7.56784 12.7708 9.39878 14.6016C11.2297 16.4324 13.6044 17.6208 16.1674 17.989C16.2201 17.9964 16.2732 18.0002 16.3264 18.0002C16.5825 18.0001 16.8313 17.9151 17.0339 17.7584C17.2365 17.6018 17.3814 17.3825 17.4461 17.1347L17.9621 15.145C18.0318 14.8804 18.0048 14.5996 17.886 14.3532C17.7673 14.1067 17.5645 13.9106 17.3141 13.8002Z"
						fill="#283033"
					/>
				</g>
				<defs>
					<clipPath>
						<rect
							width="12"
							height="12"
							fill="white"
							transform="translate(6 6)"
						/>
					</clipPath>
				</defs>
			</svg>
		</figure>
		<span
			css={css`
				${phoneNumberStyles}
			`}
		>
			{phoneNumber}
		</span>
	</>
);

const childComponents = (phoneNumber) => ({
	[IR_DOMAIN]: {
		[irConstants.MEDICARE_ADVANTAGE_PATH]: phoneLinkChildComponent(phoneNumber),
		[irConstants.MEDICARE_SUPPLEMENT_PATH]:
			phoneLinkChildComponent(phoneNumber),
		[irConstants.IR_HEALTH_INSURANCE_DEFAULT_PATH]:
			phoneLinkChildComponent(phoneNumber),
	},
	[FVY_DOMAIN]: {
		[SOLAR_PATH]: phoneLinkChildComponent(
			phoneNumber,
			"font-weight: 700; color: #ffffff;",
		),
	},
});

export const PhoneLink = ({ company }) => {
	const vertical = useSelector(selectors.getVertical);
	const phoneNumber = useSelector((state) =>
		selectors.getCompanyPhoneNumber(state, { company }),
	);
	const phoneNumberHref = useSelector((state) =>
		selectors.getCompanyPhoneNumberHref(state, { company }),
	);
	const phoneCallToActionText = useSelector(selectors.getPhoneCallToActionText);

	const companySlug = useSelector((state) =>
		selectors.getCompanySlug(state, { company }),
	);
	const isServiceBlockLegacyStyled = useSelector(
		selectors.getIsServiceBlockLegacyStyled,
	);
	const usesRevampedPhoneLink = useSelector(
		selectors.getIsRevampedPhoneLinkUsed,
	);
	const targetStyles = isServiceBlockLegacyStyled
		? styles.legacy
		: styles.revamped;

	const linkChild = phoneNumber
		? pathOr(null, [SITE, vertical], childComponents(phoneNumber))
		: null;

	/* Only rendered if the company has a phone number and legacy ServiceBlock design/styling*/
	return phoneNumber &&
		(isServiceBlockLegacyStyled || usesRevampedPhoneLink) ? (
		<div
			css={[
				targetStyles.phoneLinkWrapper,
				usesRevampedPhoneLink && [
					targetStyles.alternateRevampedPhoneStyling.phoneLinkWrapper.base,
					prop(
						vertical,
						targetStyles.alternateRevampedPhoneStyling.phoneLinkWrapper,
					),
				],
			]}
		>
			<OutboundLink
				css={
					usesRevampedPhoneLink && [
						targetStyles.alternateRevampedPhoneStyling.outboundLink.base,
						prop(
							vertical,
							targetStyles.alternateRevampedPhoneStyling.outboundLink,
						),
					]
				}
				to={phoneNumberHref}
				data-c2c-company-slug={companySlug}
				company={company}
				openInNewTab={false}
			>
				<span
					css={[
						targetStyles.helpText,
						usesRevampedPhoneLink &&
							targetStyles.alternateRevampedPhoneStyling.helpText,
					]}
				>
					{phoneCallToActionText}
				</span>
				<br />
				<span
					css={[
						targetStyles.phoneSpan,
						usesRevampedPhoneLink &&
							targetStyles.alternateRevampedPhoneStyling.phoneSpan,
					]}
					data-company-slug={companySlug}
				>
					{phoneNumber}
				</span>
				<span
					css={[targetStyles.phoneSpan, tw`hidden`]}
					data-company-slug={companySlug}
				>
					Call Now
				</span>
				{linkChild}
			</OutboundLink>
		</div>
	) : null;
};

export default withOutboundPaths(PhoneLink);

PhoneLink.propTypes = {
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
	companyReviewPath: PropTypes.string,
	companyVisitPath: PropTypes.string,
};
