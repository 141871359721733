import OutboundLink from "@/components/general/OutboundLink";
import withOutboundPaths from "@/components/HighOrderComponents/withOutboundPaths";
import { ALWAYS_DISPLAYED_MOBILE_BENEFITS_COUNT } from "@/config/constants";
import {
	DIET_MEAL_PLANS_PATH,
	DNA_TESTING_PATH,
	HOME_WARRANTIES_PATH,
	MEAL_DELIVERY_PATH,
} from "@/config/site/favy";
import {
	getCompanyName,
	getIsAllLinkingProhibited,
	getIsTargetedPath,
	getMainBenefits,
	getPreventRenderingSoftwareColumns,
	getProductDescription,
	getPromotionText,
	getUsesNewSoftwareDesign,
	getUsesSimplifiedDesign,
	getVertical,
} from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import tw, { css } from "twin.macro";

import Promotion from "../../general/Promotion";
import Coupon from "../Coupon";
import ProductDescription from "../ProductDescription";
import ReviewWidgetSection from "../ServiceLeft/ReviewWidgetSection";
import ColumnLayout from "./ColumnLayout";
import CompanyBenefitsLinkWrapper from "./CompanyBenefitsLinkWrapper";
import ServiceRightFooter from "./ServiceRightFooter";

const styles = {
	listItemStandard: css`
		padding-left: 20px;
		background-image: url("/react-images/common/favyCheck.svg");
		background-position: left top 11px;
		${tw`text-sm leading-6 text-gray-900 flex-1 lg:text-base lg:leading-7 bg-no-repeat`}
	`,
};

const CompanyHeading = ({
	companyName,
	company,
	companyVisitPath,
	isTargetedPath,
	usesSimplifiedDesign,
}) => {
	const productDescription = useSelector((state) =>
		getProductDescription(state, { company }),
	);
	const headingText =
		usesSimplifiedDesign && productDescription
			? productDescription
			: companyName;
	const TextualElement = ({ companyName }) => (
		<p
			className={`tw-text-2xl tw-font-bold tw-leading-6 tw-tracking-tight tw-text-gray-900 ${
				usesSimplifiedDesign ? "tw-mb-4" : "tw-pb-2 lg:tw-pb-3"
			}`}
		>
			{headingText}
		</p>
	);
	return isTargetedPath ? (
		<OutboundLink to={companyVisitPath} company={company}>
			<TextualElement companyName={companyName} />
		</OutboundLink>
	) : (
		<TextualElement companyName={companyName} />
	);
};
CompanyHeading.propTypes = {
	company: PropTypes.object.isRequired,
	companyName: PropTypes.string.isRequired,
	companyVisitPath: PropTypes.string.isRequired,
	isTargetedPath: PropTypes.bool.isRequired,
	usesSimplifiedDesign: PropTypes.bool.isRequired,
};

export const NewSoftwareLayout = ({
	company,
	companyIndex,
	renderAllBenefits,
	companyVisitPath,
	expandBenefits,
}) => {
	const isTargetedPath = useSelector(getIsTargetedPath);
	const usesNewSoftwareDesign = useSelector(getUsesNewSoftwareDesign);
	const preventRenderingSoftwareColumns = useSelector(
		getPreventRenderingSoftwareColumns,
	);
	const companyName = useSelector((state) =>
		getCompanyName(state, { company }),
	);
	const companyBenefits = useSelector((state) =>
		getMainBenefits(state, { company }),
	).map((benefit, idx) => (
		<li css={styles.listItemStandard} key={idx}>
			{benefit}
		</li>
	));
	// eslint-disable-next-line no-magic-numbers
	const halfLength = Math.ceil(companyBenefits.length / 2);
	const firstHalfBenefits = companyBenefits.slice(0, halfLength);
	const secondHalfBenefits = companyBenefits.slice(halfLength);
	const isAllLinkingProhibited = useSelector((state) =>
		getIsAllLinkingProhibited(state, { company }),
	);
	const vertical = useSelector((state) => getVertical(state));
	const usesSimplifiedDesign = useSelector(getUsesSimplifiedDesign);
	const usesAlternateCoupon =
		[
			DIET_MEAL_PLANS_PATH,
			MEAL_DELIVERY_PATH,
			HOME_WARRANTIES_PATH,
			DNA_TESTING_PATH,
		].includes(vertical) || usesSimplifiedDesign;
	const promotionText = useSelector((state) =>
		getPromotionText(state, { company }),
	);

	return usesNewSoftwareDesign ? (
		<div css={tw`w-full lg:flex-row lg:flex flex-col`}>
			<div css={[isAllLinkingProhibited ? tw`lg:w-full` : tw`lg:w-[75%]`]}>
				<CompanyHeading
					company={company}
					companyName={companyName}
					isTargetedPath={isTargetedPath}
					companyVisitPath={companyVisitPath}
					usesSimplifiedDesign={usesSimplifiedDesign}
				/>
				{!usesAlternateCoupon && (
					<Coupon company={company} companyIndex={companyIndex} />
				)}
				{!usesSimplifiedDesign && (
					<div css={[tw`flex justify-start` && tw`lg:hidden`]}>
						<ReviewWidgetSection company={company} />
					</div>
				)}

				{!preventRenderingSoftwareColumns && (
					<ColumnLayout company={company} companyIndex={companyIndex} />
				)}
				{!usesSimplifiedDesign && (
					<>
						<div css={tw`hidden sm:flex mt-2 mb-2`}>
							<ProductDescription company={company} />
						</div>
						<div css={tw`sm:hidden`}>
							<ProductDescription company={company} />
						</div>
					</>
				)}
				<div css={tw`sm:hidden`}>
					<CompanyBenefitsLinkWrapper
						company={company}
						companyIndex={companyIndex}
					>
						<ul>
							{renderAllBenefits || companyIndex === 1
								? companyBenefits
								: companyBenefits.slice(
										0,
										ALWAYS_DISPLAYED_MOBILE_BENEFITS_COUNT,
									)}
						</ul>
					</CompanyBenefitsLinkWrapper>
				</div>
				<div css={tw`hidden sm:flex w-full`}>
					<CompanyBenefitsLinkWrapper
						company={company}
						companyIndex={companyIndex}
					>
						<div css={tw`flex justify-between`}>
							{usesSimplifiedDesign ? (
								<ul css={tw`w-full`}>{companyBenefits}</ul>
							) : (
								<>
									<ul css={tw`w-full mr-4 sm:mr-8 md:mr-4`}>
										{firstHalfBenefits}
									</ul>
									<ul css={tw`w-full`}>{secondHalfBenefits}</ul>
								</>
							)}
						</div>
					</CompanyBenefitsLinkWrapper>
				</div>
				{expandBenefits()}
			</div>
			{usesSimplifiedDesign && promotionText && (
				<div
					css={tw`flex items-center justify-center lg:mx-4 md:hidden lg:flex`}
				>
					<Promotion company={company} companyIndex={companyIndex} />
				</div>
			)}
			<div
				css={[
					tw`mt-4 lg:mt-0 lg:ml-4`,
					css`
						@media (min-width: 1101px) {
							width: 25%;
						}
						@media (max-width: 1100px) and (min-width: 990px) {
							width: 40%;
						}
					`,
					usesSimplifiedDesign &&
						css`
							@media (max-width: 1100px) and (min-width: 990px) {
								width: auto;
							}
						`,
				]}
			>
				<ServiceRightFooter company={company} companyIndex={companyIndex} />
			</div>
		</div>
	) : null;
};

NewSoftwareLayout.propTypes = {
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
	companyReviewPath: PropTypes.string,
	companyVisitPath: PropTypes.string,
	renderAllBenefits: PropTypes.bool.isRequired,
	expandBenefits: PropTypes.func.isRequired,
};

export default withOutboundPaths(NewSoftwareLayout);
