import { css } from "@emotion/react";
import tw from "twin.macro";

export const getStyles = ({ isFeatured }) => ({
	layoutWrapper: css`
		${tw`block bg-white rounded-lg overflow-hidden`}
		${isFeatured ? tw`mt-8 border-2 border-solid border-[#000]` : tw`mb-5`}
	`,
	featuredCompanyHeader: {
		wrapper: "tw-p-2 tw-bg-[#ffefcf] tw-text-center",
		contentWrapper:
			"tw-leading-6 tw-font-semibold tw-text-xs tw-text-black tw-tracking-[-0.00875rem] xs:tw-text-sm lg:tw-text-base lg:tw-tracking-[-0.01rem]",
	},
	rootGrid: css`
		${tw`flex flex-col lg:flex-row min-h-[225px] justify-center items-center lg:items-stretch pb-4 lg:pb-0`}
	`,
	col1: css`
		${tw`w-full lg:w-1/4 max-w-none flex-grow-0 flex flex-col justify-center relative`}
		flex-basis: 25%;
	`,
	serviceFlag: {
		wrapper: css`
			${tw`absolute top-[12px] flex justify-start items-center`}
		`,
		flag: css`
			${tw`bg-[#1a1a1a] w-[28px] h-[28px] text-white font-bold flex justify-center items-center`}
		`,
		number: css`
			${tw`mt-0.5`}
		`,
		text: css`
			${tw`bg-[#1a1a1a] text-white h-[28px] text-sm flex justify-center items-center px-2 ml-0.5`}
		`,
	},
	center: css`
		${tw`pb-4 pt-16 lg:p-0`}
	`,
	companyLogo: {
		picture: css`
			${tw`flex justify-center`}
		`,
		image: [
			css`
				${tw`max-w-[230px] lg:max-w-[160px]`}
			`,
		],
	},
	col2: css`
		${tw`w-[90%] lg:w-[40%] max-w-none flex-grow-0 flex p-0 lg:pr-1 justify-center items-center mb-3`}
		flex-basis: 40%;
	`,
	col3: css`
		${tw`w-[90%] lg:w-[13%] mb-4 bg-[#F5F5F4] max-w-none flex-grow-0 flex flex-col justify-center items-center`}
		flex-basis: 13%;
		border-radius: 8px;

		@media (min-width: 1024px) {
			border-radius: 0 0 8px 8px;
		}
	`,
	col4: css`
		${tw`w-full lg:w-[22%] max-w-none flex-grow-0 flex flex-row flex-wrap lg:flex-col justify-evenly lg:justify-center items-center`}
		flex-basis: 100%;

		@media (min-width: 1024px) {
			flex-basis: 22%;
		}
	`,
	benefits: {
		wrapper: css`
			${tw`w-full px-0`}
		`,
		list: css`
			${tw`max-w-[360px] lg:max-w-none mx-auto w-full mt-4 mb-4 lg:mb-0 p-0`}
		`,
		benefit: css`
			${tw`w-full text-sm text-[#000a25] pl-6 mb-2`}
			background-image: url("/react-images/common/favyCheck.svg");
			background-position: left top 7px;
			background-repeat: no-repeat;
		`,
	},
	socialProofSection: css`
		${tw`flex-shrink lg:flex-shrink-0 flex-grow lg:flex-grow-0 mb-4 lg:mt-0`}
		flex-basis: 100%;

		@media (min-width: 1024px) {
			flex-basis: 0;
		}
	`,
	socialProof: {
		wrapper:
			"tw-relative tw-flex tw-max-w-fit tw-self-center tw-mt-3 tw-mx-auto tw-rounded-lg tw-bg-white tw-p-2 [filter:drop-shadow(0px_0px_1px_rgba(88,102,126,0.48))_drop-shadow(0px_0px_24px_rgba(88,102,126,0.10))] after:tw-absolute after:tw-right-[calc(50%-6px)] after:tw-w-2.5 after:tw-h-2.5 after:tw-translate-x-0 after:tw--translate-y-2/4 after:tw-rotate-45 after:tw-bg-white after:tw-overflow-hidden after:tw-pointer-events-none md:after:tw-w-3 md:after:tw-h-3 after:tw-top-0",
		contentWrapper:
			"tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2",
		contentIcon: "tw-text-black tw-text-2xl md:tw-text-[32px]",
		content:
			"tw-min-w-[100px] tw-max-w-[100px] tw-font-bold tw-font-aktivGrotesk !tw-m-0 tw-text-[0.6875rem] tw-text-left tw-text-[#22293A]",
	},
	rating: {
		wrapper: css`
			${tw`flex flex-col justify-center items-center`}
		`,
		score: css`
			${tw`font-extrabold text-[#1a1a1a] text-[40px]`}
		`,
		stars: css`
			${tw`flex justify-center`}
		`,
		starColor: css`
			${tw`text-[#dea112]`}
		`,
		count: css`
			${tw`text-sm font-semibold text-[#1a1a1a] -tracking-[0.5px]`}
		`,
	},
	phoneLink: {
		wrapper: css`
			${tw`flex flex-col justify-center items-center my-2`}
		`,
		imageWrapper: css`
			${tw`flex justify-center`}
		`,
		phone: css`
			${tw`text-xs mx-1 text-black`}
		`,
		phoneNumber: css`
			${tw`font-semibold text-[#1a1a1a]`}
		`,
		text: css`
			${tw`font-semibold text-[#1a1a1a] text-xs uppercase m-0`}
		`,
	},
	visitLink: {
		wrapper:
			"tw-my-2 tw-mx-0 tw-w-[165px] tw-h-[48px] tw-flex tw-bg-[linear-gradient(104deg,#EFC648_20.9%,#FCE887_51.23%,#EFC648_76.23%)] tw-rounded-lg tw-cursor-pointer lg:tw-w-[180px] xl:tw-w-[196px]",
		contentWrapper:
			"tw-text-[#422006] tw-w-full tw-flex tw-items-center tw-justify-center tw-font-semibold tw-text-base tw-mt-0",
	},
});
