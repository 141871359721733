import { useEffect } from "react";
import OutboundLink from "@/components/general/OutboundLink";
import withOutboundPaths from "@/components/HighOrderComponents/withOutboundPaths";
import {
	FILTERING_PARAMETERS_SET,
	FVY_DOMAIN,
	IR_DOMAIN,
	SITE,
	TCR_DOMAIN,
} from "@/config/constants";
import {
	ACCOUNTS_PAYABLE_PATH,
	BUSINESS_PASSWORD_MANAGERS_PATH,
	BUSINESS_VPN_PATH,
	CELL_PHONE_PLANS_PATH,
	DIET_MEAL_PLANS_PATH,
	FLOWER_DELIVERY_PATH,
	GLOBAL_HR_PATH,
	HOME_WARRANTIES_PATH,
	MATTRESS_TOPPERS_PATH,
	MATTRESSES_PATH,
	MEAT_DELIVERY_SERVICES_PATH,
	MORTGAGE_PATH,
	PDF_EDITORS_PATH,
	PERSONAL_VPN_PATH,
	PET_FOOD_DELIVERY_PATH,
	SOLAR_PATH,
	WINE_CLUBS_PATH,
} from "@/config/site/favy";
import * as irConstants from "@/config/site/insuranceranked";
import {
	BUSINESS_BANKING_PATH,
	BUSINESS_LOANS_DEFAULT_PATH,
	FINANCIAL_ADVISORS_PATH,
	PERSONAL_LOANS_DEFAULT_PATH,
	SPEND_MANAGEMENT_PATH,
	TAX_PREPARATION_SERVICES_PATH,
} from "@/config/site/thecreditreview";
import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { path, pathOr } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import tw, { css } from "twin.macro";

const visitLinkChildComponent = () => (
	<figure className="tw-mb-auto tw-ml-2 tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center">
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="12"
				cy="12"
				r="11"
				fill="#2299DC"
				stroke="white"
				strokeWidth="2"
			/>
			<path
				d="M17.707 11.293L13.707 7.293C13.3165 6.90253 12.6835 6.90253 12.293 7.293C11.9025 7.68346 11.9025 8.31653 12.293 8.707L14.586 11H7C6.44771 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H14.586L12.293 15.293C11.9025 15.6835 11.9025 16.3165 12.293 16.707C12.6835 17.0975 13.3165 17.0975 13.707 16.707L17.707 12.707C17.8945 12.5195 17.9998 12.2652 17.9998 12C17.9998 11.7348 17.8945 11.4805 17.707 11.293Z"
				fill="white"
			/>
		</svg>
	</figure>
);

const styles = {
	VisitLink: {
		legacy: {
			div: css`
				line-height: 1.1;
				margin-right: 10px;

				@media (max-width: 450px) {
					margin-right: 0px;
				}
			`,
			small: css`
				font-size: 12px;
				color: rgba(255, 255, 255, 0.8);
				display: block;
				font-family: AvenirNextLTPro-Demi;
			`,
			span: css`
				font-size: 1.3rem;
				font-weight: 400;
				font-family: Montserrat;
				text-align: left;
				display: block;
			`,
			arrow: css`
				max-width: 22px;

				@media (max-width: 767px) {
					display: none;
				}
			`,
			white: css`
				fill: #fff;
				fill-rule: evenodd;
			`,
		},
	},
	OutboundLink: {
		legacy: css`
			width: 210px;
			border-radius: 3px;
			-webkit-box-pack: center;
			justify-content: center;
			color: white;
			background-color: #6ac54e;
			box-shadow: 0 3px 8px rgba(42, 90, 27, 0.33);
			padding: 13px 20px;
			display: inline-flex;
			-webkit-box-align: center;
			align-items: center;
			&:hover {
				color: white;
				background-color: #14a929;

				svg {
					transform: translateX(5px);
				}
			}

			@media (max-width: 767px) {
				margin-left: 0;
				position: relative;
				left: 0;
				width: 160px;
				padding: 10px;
			}

			@media (max-width: 450px) {
				max-width: 150px;
			}
		`,
		revamped: css`
			font-family: Montserrat;
			width: 175px;
			height: 46px;
			line-height: 1;
			box-shadow: none;
			font-size: 14px;
			font-weight: 700;
			color: #fff;
			text-transform: none;
			position: relative;
			border-radius: 3px;
			display: inline-flex;

			justify-content: space-evenly;
			background-color: #6ac54e;
			-webkit-box-align: center;
			align-items: center;

			&:hover {
				color: white;
				background-color: #14a929;

				:after {
					transform: translate(5px);
				}
			}

			:before {
				content: "";
				background-image: url("/react-images/common/dollarImage.svg");
				background-repeat: no-repeat;
				background-size: cover;
				width: 23px;
				height: 23px;
			}

			:after {
				content: "";
				background-image: url("/react-images/common/forwardArrow.svg");
				background-repeat: no-repeat;
				background-size: cover;
				width: 6px;
				height: 10px;
				transition: 0.4s ease-in-out;
			}

			@media (max-width: 1199px) {
				font-size: 14px;
				width: 160px;
			}

			@media (max-width: 580px) {
				width: 75%;
				max-width: 210px;
			}
		`,
		domainLevelOverrides: {
			[TCR_DOMAIN]: {
				[TAX_PREPARATION_SERVICES_PATH]: css`
					font-size: 16px !important;
					@media (min-width: 1199px) {
						margin-left: 2rem;
					}
					${tw`focus:text-white`}
				`,
				[PERSONAL_LOANS_DEFAULT_PATH]: css`
					${tw`bg-tcr-personal-loans-green font-avenirNextDemi !w-[180px] !justify-center hover:bg-tcr-personal-loans-green !text-xl before:hidden hover:after:transform-none focus:text-white`}
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/common/newTab.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 16px;
						height: 16px;
						margin-left: 10px;
					}
				`,
				[BUSINESS_LOANS_DEFAULT_PATH]: css`
					${tw`bg-tcr-personal-loans-green font-avenirNextDemi !w-[180px] !justify-center hover:bg-tcr-personal-loans-green !text-xl before:hidden hover:after:transform-none focus:text-white`}
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/common/newTab.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 16px;
						height: 16px;
						margin-left: 10px;
					}
				`,
				[BUSINESS_BANKING_PATH]: css`
					${tw`bg-tcr-personal-loans-green font-avenirNextDemi !w-[180px] !justify-center hover:bg-tcr-personal-loans-green !text-xl before:hidden hover:after:transform-none focus:text-white`}
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/common/newTab.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 16px;
						height: 16px;
						margin-left: 10px;
					}
				`,
				[SPEND_MANAGEMENT_PATH]: css`
					${tw`bg-tcr-personal-loans-green font-avenirNextDemi !w-[180px] !justify-center hover:bg-tcr-personal-loans-green !text-xl before:hidden hover:after:transform-none focus:text-white`}
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/common/newTab.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 16px;
						height: 16px;
						margin-left: 10px;
					}
				`,
				[FINANCIAL_ADVISORS_PATH]: css`
					${tw`bg-tcr-personal-loans-green font-avenirNextDemi !w-[180px] !justify-center hover:bg-tcr-personal-loans-green !text-xl before:hidden hover:after:transform-none focus:text-white`}
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/common/newTab.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 16px;
						height: 16px;
						margin-left: 10px;
					}
				`,
			},
			[IR_DOMAIN]: {
				allVerticals: css`
					font-family: Graphik Web;
					font-weight: 600;
					border-radius: 5px;
					background-color: #1d8ce0;
					justify-content: space-evenly;
					width: 140px;

					:before {
						display: none;
					}

					:after {
						content: "";
						background-image: url("/react-images/insuranceranked/dollarImage.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 23px;
						height: 23px;
					}

					:hover {
						background-color: #1d8ce0;
						:after {
							transform: unset;
						}
					}

					@media (max-width: 580px) {
						justify-content: center;

						:after {
							margin-left: 10px;
						}
					}
				`,
				[irConstants.IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH]: css`
					width: 170px;
					font-size: 20px !important;
				`,
				[irConstants.IR_LIFE_INSURANCE_DEFAULT_PATH]: css`
					${tw`bg-tcr-personal-loans-green !rounded-[3px] font-avenirNextDemi !w-[180px] !justify-center hover:bg-tcr-personal-loans-green !text-xl before:hidden hover:after:transform-none focus:text-white`}
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/common/newTab.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 16px;
						height: 16px;
						margin-left: 10px;
					}
				`,
				[irConstants.IR_PET_INSURANCE_DEFAULT_PATH]: css`
					${tw`bg-tcr-personal-loans-green !rounded-[3px] font-avenirNextDemi !w-[180px] !justify-center hover:bg-tcr-personal-loans-green !text-xl before:hidden hover:after:transform-none focus:text-white`}
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/common/newTab.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 16px;
						height: 16px;
						margin-left: 10px;
					}
				`,
				[irConstants.BUSINESS_INSURANCE_PATH]: tw`!text-xl w-[170px]`,
				[irConstants.TRAVEL_INSURANCE_PATH]: css`
					${tw`bg-tcr-personal-loans-green !rounded-[3px] font-avenirNextDemi !w-[180px] !justify-center hover:bg-tcr-personal-loans-green !text-xl before:hidden hover:after:transform-none focus:text-white`}
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/common/newTab.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 16px;
						height: 16px;
						margin-left: 10px;
					}
				`,
			},
			[FVY_DOMAIN]: {
				allVerticals: css`
					:before,
					:after {
						display: none;
					}

					height: 40px;
					width: 147px;

					${tw`flex justify-center text-sm text-white leading-6 p-2 rounded-full bg-green-600 bg-no-repeat duration-100 hover:bg-green-700`}
				`,
				[WINE_CLUBS_PATH]: css`
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/wineGlass.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 23px;
						height: 23px;
					}

					&:hover {
						:after {
							transform: none;
						}
					}

					@media (max-width: 580px) {
						:after {
							margin-left: 5px;
						}

						:before {
							display: unset;
							content: "";
							background-image: url("/react-images/favy/wineGlass.svg");
							background-repeat: no-repeat;
							background-size: cover;
							width: 23px;
							height: 23px;
							margin-right: 5px;
						}
					}

					${tw`bg-red-800 hover:bg-red-900`}
				`,
				[MEAT_DELIVERY_SERVICES_PATH]: css`
					width: 152px;

					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/steak.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 27px;
						height: 27px;
						margin-left: 5px;
					}

					&:hover {
						:after {
							transform: none;
						}
					}

					@media (max-width: 580px) {
						:after {
							margin-left: 5px;
						}

						:before {
							display: unset;
							content: "";
							background-image: url("/react-images/favy/steak.svg");
							background-repeat: no-repeat;
							background-size: cover;
							width: 27px;
							height: 27px;
							margin-right: 5px;
						}
					}
					${tw`bg-red-600 hover:bg-red-700`}
				`,
				[PET_FOOD_DELIVERY_PATH]: css`
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/paw.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 24px;
						height: 24px;
						margin-left: 5px;
					}

					&:hover {
						:after {
							transform: none;
						}
					}

					@media (max-width: 580px) {
						:after {
							margin-left: 5px;
						}

						:before {
							display: unset;
							content: "";
							background-image: url("/react-images/favy/paw.svg");
							background-repeat: no-repeat;
							background-size: cover;
							width: 24px;
							height: 24px;
							margin-right: 5px;
						}
					}
				`,
				[MATTRESSES_PATH]: css`
					width: 170px;

					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/moon.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 24px;
						height: 24px;
						margin-left: 10px;
					}

					:before {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/moon.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 24px;
						height: 24px;
						margin-right: 5px;
					}

					&:hover {
						:after {
							transform: none;
						}
					}

					@media (max-width: 580px) {
						:after {
							margin-left: 10px;
						}
					}
					${tw`bg-blue-700 hover:bg-blue-800`}
				`,
				[MATTRESS_TOPPERS_PATH]: css`
					width: 170px;

					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/moon.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 24px;
						height: 24px;
						margin-left: 10px;
					}

					:before {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/moon.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 24px;
						height: 24px;
						margin-right: 5px;
					}

					&:hover {
						:after {
							transform: none;
						}
					}

					@media (max-width: 1199px) {
						width: 170px;
					}

					@media (max-width: 580px) {
						:after {
							margin-left: 10px;
						}
					}
					${tw`bg-blue-700 hover:bg-blue-800`}
				`,
				[BUSINESS_PASSWORD_MANAGERS_PATH]: css`
					${tw`bg-blue-600 hover:bg-blue-700`}
				`,
				[MORTGAGE_PATH]: css`
					width: 170px;

					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/house.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 24px;
						height: 24px;
						margin-left: 5px;
					}

					:before {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/house.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 24px;
						height: 24px;
						margin-right: 5px;
					}

					&:hover {
						:after {
							transform: none;
						}
					}

					@media (max-width: 580px) {
						:after {
							margin-left: 5px;
						}
					}
				`,
				[SOLAR_PATH]: css`
					width: 175px;

					font-size: 16px;

					@media (max-width: 580px) {
						width: 175px;
					}

					@media (max-width: 1199px) {
						width: 175px;
						font-size: 16px;
					}

					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/sun.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 24px;
						height: 24px;
						margin-left: 10px;
					}

					:before {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/sun.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 24px;
						height: 24px;
						margin-right: 10px;
					}

					&:hover {
						:after {
							transform: none;
						}
					}

					${tw`hover:bg-blue-700 bg-blue-700 text-white`}
				`,
				[BUSINESS_VPN_PATH]: tw`!bg-[#359492]`,
				[PERSONAL_VPN_PATH]: css`
					width: 165px;

					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/networkLock.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 24px;
						height: 24px;
						margin-left: 5px;
					}

					:before {
						display: unset;
						content: "";
						background-image: url("/react-images/favy/vpnLock.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 24px;
						height: 24px;
						margin-right: 5px;
					}

					&:hover {
						:after {
							transform: none;
						}
					}

					@media (max-width: 580px) {
						:after {
							margin-left: 5px;
						}
					}
				`,
				[ACCOUNTS_PAYABLE_PATH]: css`
					${tw`bg-blue-500 hover:bg-blue-600`}
				`,
				[GLOBAL_HR_PATH]: css`
					${tw`bg-red-500 hover:bg-red-600`}
				`,
				[PDF_EDITORS_PATH]: tw`bg-[#359492] hover:bg-[#3EAEAB]`,
				[FLOWER_DELIVERY_PATH]: css`
					${tw`bg-tcr-personal-loans-green !rounded-[3px] font-avenirNextDemi !w-[180px] !justify-center hover:bg-tcr-personal-loans-green !text-xl before:hidden hover:after:transform-none focus:text-white`}
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/common/newTab.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 16px;
						height: 16px;
						margin-left: 10px;
					}
				`,
				[CELL_PHONE_PLANS_PATH]: css`
					${tw`bg-tcr-personal-loans-green !rounded-[3px] font-avenirNextDemi !w-[200px] !justify-center hover:bg-tcr-personal-loans-green !text-xl before:hidden hover:after:transform-none focus:text-white`}
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/common/newTab.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 16px;
						height: 16px;
						margin-left: 10px;
					}
				`,
				[HOME_WARRANTIES_PATH]: css`
					${tw`bg-tcr-personal-loans-green !rounded-[3px] font-avenirNextDemi !w-[200px] !justify-center hover:bg-tcr-personal-loans-green !text-xl before:hidden hover:after:transform-none focus:text-white`}
					:after {
						display: unset;
						content: "";
						background-image: url("/react-images/common/newTab.svg");
						background-repeat: no-repeat;
						background-size: cover;
						width: 16px;
						height: 16px;
						margin-left: 10px;
						position: relative;
						top: -2px;
					}
				`,
			},
		},
		matchRevampedPhone: [
			css`
				line-height: 1;
				:before,
				:after {
					display: none;
				}
				width: 175px;
				font-family: Montserrat;

				@media (max-width: 1199px) {
					width: 175px;
					font-size: 14px;
				}

				:hover {
					background-color: #1d8ce0;
					:after {
						transform: unset;
					}
				}
			`,
			tw`cursor-pointer inline-flex items-center justify-center text-sm font-bold py-3  my-1 rounded bg-blue-500 text-white hover:bg-blue-600`,
		],
	},
};

const childComponents = {
	[IR_DOMAIN]: {
		[irConstants.MEDICARE_DEFAULT_PATH]: visitLinkChildComponent(),
		[irConstants.MEDICARE_ADVANTAGE_PATH]: visitLinkChildComponent(),
		[irConstants.MEDICARE_SUPPLEMENT_PATH]: visitLinkChildComponent(),
		[irConstants.IR_HEALTH_INSURANCE_DEFAULT_PATH]: visitLinkChildComponent(),
	},
};

export const VisitLink = ({
	company,
	companyVisitPath,
	linkTextOverride,
	styleOverride,
	variant,
}) => {
	const dispatch = useDispatch();
	useEffect(
		function dispatchVariation() {
			if (variant) {
				dispatch({
					type: FILTERING_PARAMETERS_SET,
					payload: { variationId: "v2cta" },
				});
			}
		},
		[variant, companyVisitPath],
	);
	const companyName = useSelector((state) =>
		selectors.getCompanyName(state, { company }),
	);
	/* TODO remove overrides to CTA text and className once IR content is 100% compliant. See https://applieddev.atlassian.net/browse/FUID-218 */
	const linkCallToActionText =
		linkTextOverride ||
		useSelector((state) =>
			selectors.getLinkCallToActionText(state, { company }),
		);
	const isServiceBlockLegacyStyled = useSelector(
		selectors.getIsServiceBlockLegacyStyled,
	);
	const isSoftwareDesignWithoutReviewLink = useSelector(
		selectors.getPreventRenderingSoftwareReviewLink,
	);
	const vertical = useSelector(selectors.getVertical);
	const usesRevampedPhoneLink = useSelector(
		selectors.getIsRevampedPhoneLinkUsed,
	);
	const usesNewSoftwareDesign = useSelector(selectors.getUsesNewSoftwareDesign);
	const isProductListingPage = useSelector(selectors.getIsProductListingPage);
	const usesSimplifiedDesign = useSelector(selectors.getUsesSimplifiedDesign);
	const outboundLinkStyles = styleOverride || [
		isServiceBlockLegacyStyled
			? styles.OutboundLink.legacy
			: styles.OutboundLink.revamped,
		path(
			["OutboundLink", "domainLevelOverrides", SITE, "allVerticals"],
			styles,
		),
		path(["OutboundLink", "domainLevelOverrides", SITE, vertical], styles),
		usesRevampedPhoneLink &&
			![FVY_DOMAIN].includes(SITE) &&
			styles.OutboundLink.matchRevampedPhone,
		isSoftwareDesignWithoutReviewLink && tw`!w-[200px] !text-xl lg:mr-8`,
		usesNewSoftwareDesign && !usesSimplifiedDesign && tw`!w-[200px] !text-xl`,
		variant &&
			css`
				width: auto !important;
				font-size: 20px !important;
			`,
		vertical === DIET_MEAL_PLANS_PATH && tw`!text-base`,
		isProductListingPage &&
			css`
				${tw`bg-[#359492] font-avenirNextDemi !w-[180px] !justify-center hover:bg-[#359492] !text-xl before:hidden hover:after:transform-none focus:text-white`}
				:after {
					display: unset;
					content: "";
					background-image: url("/react-images/common/newTab.svg");
					background-repeat: no-repeat;
					background-size: cover;
					width: 16px;
					height: 16px;
					margin-left: 10px;
				}
			`,
		usesSimplifiedDesign &&
			css`
				${tw`!w-full !max-w-[100%] sm:!w-[160px] ml-auto mr-auto bg-tcr-personal-loans-green !rounded-full font-avenirNextDemi !justify-center hover:bg-tcr-personal-loans-green !text-xl before:hidden hover:after:transform-none focus:text-white !h-[55px]`}
				:after {
					display: unset;
					content: "";
					background-image: url("/react-images/common/newTab.svg");
					background-repeat: no-repeat;
					background-size: cover;
					width: 16px;
					height: 16px;
					margin-left: 10px;
					position: relative;
					top: -2px;
				}
			`,
	];
	const visitLinkChild = pathOr(null, [SITE, vertical], childComponents);

	const legacyContent = (
		<>
			<div css={styles.VisitLink.legacy.div}>
				<small css={styles.VisitLink.legacy.small}>{companyName}</small>
				<span css={styles.VisitLink.legacy.span}>{linkCallToActionText}</span>
			</div>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="28"
				height="19.969"
				viewBox="0 0 28 19.969"
				css={styles.VisitLink.legacy.arrow}
				focusable="false"
			>
				<path
					css={styles.VisitLink.legacy.white}
					d="M611.855,211.3a0.978,0.978,0,0,0-1.411,0,1.022,1.022,0,0,0,0,1.419l7.155,7.269H593.988a0.991,0.991,0,0,0-.988,1,1,1,0,0,0,.988,1.018H617.6l-7.155,7.255a1.039,1.039,0,0,0,0,1.433,0.978,0.978,0,0,0,1.411,0l8.849-8.989a1,1,0,0,0,0-1.419Z"
					transform="translate(-593 -211.031)"
				/>
			</svg>
		</>
	);

	return (
		<OutboundLink
			css={outboundLinkStyles}
			to={companyVisitPath}
			company={company}
			data-cy="service-block-visit-link"
		>
			{isServiceBlockLegacyStyled ? legacyContent : linkCallToActionText}
			{visitLinkChild}
		</OutboundLink>
	);
};

export default withOutboundPaths(VisitLink);

VisitLink.propTypes = {
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
	companyReviewPath: PropTypes.string,
	companyVisitPath: PropTypes.string,
	linkTextOverride: PropTypes.string,
	styleOverride: PropTypes.object,
	variant: PropTypes.string,
};
