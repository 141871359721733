import { useEffect, useState } from "react";
import { HAIR_GROWTH_PATH, MORTGAGE_PATH } from "@/config/site/favy";
import {
	getGender,
	getMortgageType,
	getTargetedSlug,
	getVertical,
} from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import tw from "twin.macro";

const WOMENS_HAIR_GROWTH_TARGETED_PAGE_SLUGS = [
	"best-hair-regrowth-for-women",
	"nutrafol-for-women-reviews",
	"rogaine-for-women-reviews",
	"nutrafol-bad-reviews",
	"nutrafol-hair-growth-reviews",
];

const styles = {
	[MORTGAGE_PATH]: {
		filterContainer: tw`flex items-center justify-between bg-fvy-purple w-full px-4 md:p-2`,
		buttonContainer: tw`flex items-center justify-center rounded-full md:rounded-none bg-white md:bg-fvy-purple ml-2 md:m-0 p-1 md:p-0`,
		button: tw`text-sm md:text-base text-black md:text-white hover:md:text-black w-20 md:w-32 py-1 md:py-2 px-1 md:px-5 mx-1 md:mx-2 rounded-full bg-white md:bg-fvy-purple border-0 md:border-2 md:border-white md:border-solid hover:cursor-pointer hover:text-white md:hover:text-black md:hover:bg-white`,
		selected: tw`bg-[#359492] md:bg-white hover:md:text-black text-white md:text-black hover:cursor-default`,
	},
	[HAIR_GROWTH_PATH]: {
		filterContainer: tw`flex flex-col md:flex-row items-center justify-start bg-white w-full px-4 md:p-2`,
		buttonContainer: tw`flex items-center justify-center rounded-full bg-[#F0F0F0] ml-0 mt-2 md:mt-0 md:ml-3 py-2 px-1`,
		button: tw`text-xs md:text-sm text-black md:text-gray-600 hover:md:text-black font-semibold w-[90px] py-1 md:py-2 px-1 mx-1 rounded-full bg-transparent border-0 hover:cursor-pointer hover:text-white md:hover:text-black md:hover:bg-white`,
		selected: tw`bg-white !text-[#e1255b]`,
	},
};

export const DesktopFilteringButtonSwitch = ({
	options,
	buildFilteringState,
	filteringKey,
}) => {
	const targetedSlug = useSelector(getTargetedSlug);
	const vertical = useSelector(getVertical);
	const verticalStyles = styles[vertical];

	const FilteringHeadlineMortgage = () => (
		<figure css={tw`flex items-center`}>
			<img
				src="/react-images/favy/mortgageHouse.svg"
				css={tw`w-8 h-8 mr-3 hidden md:block`}
			/>
			<p css={tw`text-lg text-white font-semibold hidden md:block`}>
				What type of Mortgage do you need?
			</p>
			<p css={tw`text-lg text-white font-semibold md:hidden`}>Mortgage Type</p>
		</figure>
	);

	const FilteringHeadlineHairGrowth = () => (
		<figure css={tw`flex items-center`}>
			<img src="/react-images/favy/genderIcon.svg" css={tw`w-8 h-8 mr-3`} />
			<p css={tw`text-base text-black font-semibold`}>Select Your Gender</p>
		</figure>
	);

	const overrideGenderFilter =
		WOMENS_HAIR_GROWTH_TARGETED_PAGE_SLUGS.includes(targetedSlug);
	const gender = overrideGenderFilter ? "women" : "men";

	const mortgageType = useSelector(getMortgageType);

	const [selected, setSelected] =
		vertical === HAIR_GROWTH_PATH
			? useState(getGender)
			: useState(mortgageType);

	useEffect(() => {
		if (vertical === MORTGAGE_PATH) {
			setSelected(mortgageType);
		}
	});

	useEffect(() => {
		if (vertical === HAIR_GROWTH_PATH) {
			buildFilteringState({ [filteringKey]: gender });
			setSelected(gender);
		}
	}, []);

	const handleClick = (e) => {
		buildFilteringState({ [filteringKey]: e.target.value });
		setSelected(e.target.value);
	};

	const buttons = options.map((option, idx) => (
		<button
			key={idx}
			value={option.value}
			onClick={handleClick}
			css={[
				verticalStyles.button,
				selected === option.value
					? verticalStyles.selected
					: tw`hover:text-black`,
			]}
		>
			{option.label}
		</button>
	));

	return (
		<div css={verticalStyles.filterContainer}>
			<div css={verticalStyles.headline}>
				{vertical === HAIR_GROWTH_PATH ? (
					<FilteringHeadlineHairGrowth />
				) : (
					<FilteringHeadlineMortgage />
				)}
			</div>
			<div css={verticalStyles.buttonContainer}>{buttons}</div>
		</div>
	);
};

export default DesktopFilteringButtonSwitch;

DesktopFilteringButtonSwitch.propTypes = {
	options: PropTypes.array,
	buildFilteringState: PropTypes.func,
	filteringKey: PropTypes.string,
};
