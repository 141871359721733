import { useContext } from "react";
import PropTypes from "prop-types";

import { CompanyContext } from "../CompanyList/CompanyContext";

export const BaseBenefitsList = ({ style, classNames, icon = null }) => {
	const { company } = useContext(CompanyContext);
	const benefitsArray = company.fields.companyMainBenefits;

	const companyBenefits = benefitsArray.map((benefit, idx) => (
		<li key={idx} css={style?.benefit} className={classNames?.benefit}>
			{icon}
			{benefit}
		</li>
	));

	return (
		<div css={style?.wrapper} className={classNames?.wrapper}>
			<ul css={style?.list} className={classNames?.list}>
				{companyBenefits}
			</ul>
		</div>
	);
};

export default BaseBenefitsList;

BaseBenefitsList.propTypes = {
	style: PropTypes.object,
	classNames: PropTypes.object,
	company: PropTypes.object,
	icon: PropTypes.element,
};
