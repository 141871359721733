import PropTypes from "prop-types";

import GridLayoutWithPhoneCta from "./GridLayoutWithPhoneCta";
import { ThemeContext } from "./ThemeContext";

export const CreditAssistanceCompany = (props) => (
	<ThemeContext.Provider
		value={{
			phoneCtaText: "Free Credit Consultation",
		}}
	>
		<GridLayoutWithPhoneCta {...props} />
	</ThemeContext.Provider>
);

export default CreditAssistanceCompany;

CreditAssistanceCompany.propTypes = {
	isFeaturedCompany: PropTypes.bool,
};
