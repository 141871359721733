import { useEffect } from "react";

import { noop } from "../helpers/noop";
import { useIsFirstRender } from "./useIsFirstRender";

/**
 * Effect hook that ignores the first render (not invoked on mount).
 *
 * @param effect Effector to run on updates
 * @param deps Dependencies list, as for `useEffect` hook
 */
export const useUpdateEffect = (effect, deps) => {
	const isFirstRender = useIsFirstRender();

	useEffect(isFirstRender ? noop : effect, deps);
};
