// import constants
import { FVY_DOMAIN, IR_DOMAIN, SITE, TCR_DOMAIN } from "@/config/constants";
import {
	COFFEE_CLUBS_PATH,
	MATTRESS_TOPPERS_PATH,
	MATTRESSES_PATH,
	MEAT_DELIVERY_SERVICES_PATH,
	PET_FOOD_DELIVERY_PATH,
	PROMOTIONAL_PRODUCTS_PATH,
	SOLAR_PATH,
	SURVEY_SITES_PATH,
	TEA_CLUBS_PATH,
} from "@/config/site/favy";
import * as tcrConstants from "@/config/site/thecreditreview";
import {
	getCompanyListHeaderColumnCount,
	getHeaderParts,
	getPreventRenderingCompanyListHeader,
	getVertical,
} from "@/selectors";
import { css } from "@emotion/react";
import { path } from "ramda";
import { useSelector } from "react-redux";
import tw from "twin.macro";

const styles = {
	CompanyListHeader: {
		headerWrapper: {
			baseStyles: css`
				margin-top: 30px;
				background-color: #fff;

				@media (max-width: 991px) {
					display: none;
				}
			`,
			domainLevelOverrides: {
				[IR_DOMAIN]: {
					allVerticals: css`
						margin-top: 0;
						background-color: #f5f8fa;
					`,
				},
				[TCR_DOMAIN]: {
					[tcrConstants.BANKRUPTCY_PATH]: css`
						margin-top: 1.125rem;
					`,
					[tcrConstants.STUDENT_LOANS_REFINANCE_DEFAULT_PATH]: css`
						margin-top: 15px;
					`,
					[tcrConstants.TAX_PREPARATION_SERVICES_PATH]: tw`mt-5 mb-5`,
				},
				[FVY_DOMAIN]: {
					allVerticals: css`
						margin-top: 0px;
					`,
				},
			},
		},

		headerContents: {
			baseStyles: css`
				display: flex;
				margin: 0;
				align-items: center;
				list-style-type: none;

				@media (max-width: 1199px) {
					padding-left: 10px;
				}

				li {
					font-family: Graphik Web;
					font-weight: 600;
					background-image: none;
					padding: 0;
					font-size: 12px;
					color: #787e81;

					letter-spacing: 0.03em;
					margin: 0;

					width: 100%;

					@media (max-width: 1199px) {
						font-size: 11px;
					}
				}
			`,
			domainLevelOverrides: {
				[IR_DOMAIN]: {
					allVerticals: css`
						line-height: 12px;
					`,
				},
				[TCR_DOMAIN]: {
					allVerticals: css`
						li {
							font-family: Montserrat;
							text-transform: uppercase;
							line-height: 1.3;
							color: #48484b;
						}
					`,
					[tcrConstants.TAX_PREPARATION_SERVICES_PATH]: css`
						li {
							${tw`text-gray-700 normal-case text-sm tracking-normal`}
						}
					`,
				},
				[FVY_DOMAIN]: {
					allVerticals: css`
						grid-template-columns: 280px 335px 100px 100px 100px;

						@media (max-width: 1199px) {
							grid-template-columns: 260px 340px 100px 100px 100px;
						}

						@media (max-width: 1024px) {
							grid-template-columns: 240px 319px 82px 82px 82px;
						}

						li {
							color: rgb(75, 85, 99);
							${tw`text-sm leading-4 font-normal`}
						}

						li:first-of-type {
							padding-left: 10px;
						}

						li:nth-of-type(2) {
							text-align: left !important;
							margin: 0 0 0 5px !important;
						}

						li:nth-of-type(n + 3):nth-of-type(-n + 5) {
							text-align: center;
							max-width: 80px;
							margin: 0 auto;
						}
					`,
					[COFFEE_CLUBS_PATH]: css`
						grid-template-columns: 280px 342px 102px 106px 100px;
					`,
					[MEAT_DELIVERY_SERVICES_PATH]: css`
						grid-template-columns: 280px 266px 118px 118px 118px;

						@media (max-width: 1024px) {
							grid-template-columns: 240px 225px 99px 99px 82px;
						}
					`,
					[MATTRESSES_PATH]: css`
						grid-template-columns: 280px 376px 128px 118px;

						@media (max-width: 1024px) {
							grid-template-columns: 240px 348px 69px 118px;
						}
					`,
					[MATTRESS_TOPPERS_PATH]: css`
						grid-template-columns: 280px 376px 128px 118px;

						@media (max-width: 1024px) {
							grid-template-columns: 240px 376px 69px 75px;
						}
					`,
					[PET_FOOD_DELIVERY_PATH]: css`
						grid-template-columns: 280px 345px 100px 100px 100px;
					`,
					[SURVEY_SITES_PATH]: css`
						grid-template-columns: 280px 349px 119px 75px 118px;

						@media (max-width: 1280px) {
							grid-template-columns: 280px 352px 99px 75px 98px;
						}

						@media (max-width: 1024px) {
							grid-template-columns: 238px 338px 69px 75px 50px;
						}
					`,
					[SOLAR_PATH]: css`
						grid-template-columns: 280px 335px 160px 145px 100px;
					`,
					[PROMOTIONAL_PRODUCTS_PATH]: css`
						grid-template-columns: 280px 348px 100px 100px 100px;
					`,
					[TEA_CLUBS_PATH]: css`
						grid-template-columns: 280px 343px 99px 118px 90px;
						@media (max-width: 1024px) {
							grid-template-columns: 240px 278px 100px 90px 90px;
						}
					`,
				},
			},
			grid: {
				base: css`
					display: grid;
					padding: 16px 0 12px 0;

					li:first-of-type {
						padding-left: 33px;
					}

					@media (max-width: 1199px) {
						padding: 11px 0 11px 0;

						li:first-of-type {
							padding-left: 10px;
						}
					}
				`,
				[2]: css`
					grid-template-columns: 246px 177px 177px auto;

					li:nth-of-type(n + 2):nth-of-type(-n + 3) {
						text-align: center;
						max-width: 80px;
						margin: 0 auto;
					}

					@media (max-width: 1199px) {
						grid-template-columns: 197px 150px 150px auto;
					}
				`,

				[3]: css`
					grid-template-columns: 246px 118px 118px 118px auto;

					li:nth-of-type(n + 2):nth-of-type(-n + 4) {
						text-align: center;
						max-width: 80px;
						margin: 0 auto;
					}

					@media (max-width: 1199px) {
						grid-template-columns: 197px 100px 100px 100px auto;
					}
				`,

				[4]: css`
					grid-template-columns: 246px 88px 88px 88px 88px auto;

					li:nth-of-type(n + 2):nth-of-type(-n + 5) {
						text-align: center;
						max-width: 80px;
						margin: 0 auto;
					}

					@media (max-width: 1199px) {
						grid-template-columns: 197px 75px 75px 75px 75px auto;
					}
				`,
			},
		},
	},
};

export const CompanyListHeader = () => {
	const vertical = useSelector(getVertical);
	const headerParts = useSelector(getHeaderParts);
	const columnCount = useSelector(getCompanyListHeaderColumnCount);
	const preventRenderingCompanyListHeader = useSelector(
		getPreventRenderingCompanyListHeader,
	);

	return !preventRenderingCompanyListHeader && headerParts ? (
		<div
			css={[
				styles.CompanyListHeader.headerWrapper.baseStyles,
				path(
					[
						"CompanyListHeader",
						"headerWrapper",
						"domainLevelOverrides",
						SITE,
						"allVerticals",
					],
					styles,
				),
				path(
					[
						"CompanyListHeader",
						"headerWrapper",
						"domainLevelOverrides",
						SITE,
						vertical,
					],
					styles,
				),
			]}
		>
			<ul
				css={[
					styles.CompanyListHeader.headerContents.baseStyles,
					styles.CompanyListHeader.headerContents.grid.base,
					path(
						["CompanyListHeader", "headerContents", "grid", columnCount],
						styles,
					),
					path(
						[
							"CompanyListHeader",
							"headerContents",
							"domainLevelOverrides",
							SITE,
							"allVerticals",
						],
						styles,
					),
					path(
						[
							"CompanyListHeader",
							"headerContents",
							"domainLevelOverrides",
							SITE,
							vertical,
						],
						styles,
					),
				]}
			>
				{headerParts.map((part, idx) => (
					<li key={idx}>{part}</li>
				))}
			</ul>
		</div>
	) : null;
};
