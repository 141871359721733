import { Component } from "react";
import { AGE_THRESHOLD } from "@/config/constants";
// import constants
import { FILTERING_AGE_VALUE } from "@/config/site/insuranceranked";
// import selectors
import { getModalSelection, getVertical } from "@/selectors";
// import actions
import {
	deleteFilteringProperty,
	setFilteringStateWithoutTransition,
} from "@/store/filtering/actions";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import tw, { css } from "twin.macro";

const mapStateToProps = (state, ownProps) => ({
	modalSelectedOption: getModalSelection(state, ownProps),
	vertical: getVertical(state),
});

const mapDispatchToProps = {
	deleteFilteringProperty,
	setFilteringStateWithoutTransition,
};

export class FilterBooleanButtonGroup extends Component {
	state = {
		val: this.props.modalSelectedOption || false,
	};

	handleChange = (value) => {
		this.setState({
			val: value,
		});
		if (value) {
			this.props.setFilteringStateWithoutTransition({
				[AGE_THRESHOLD]: FILTERING_AGE_VALUE,
			});
		} else this.props.deleteFilteringProperty(AGE_THRESHOLD);
		this.props.buildFilteringState(
			{ [this.props.filteringKey]: value },
			this.props.isMobile,
		);
	};

	render() {
		const { title, modalSelectedOption } = this.props;
		const value = modalSelectedOption || this.state.val;

		return (
			<div css={tw`flex w-full justify-between items-center`}>
				<div
					css={[
						css`
							color: white;
						`,
						tw`font-semibold`,
					]}
				>
					<div css={tw`flex w-full justify-between items-center`}>
						<svg
							css={css`
								@media (max-width: 540px) {
									height: 30px;
								}
							`}
							width="38"
							height="36"
							viewBox="0 0 38 36"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0 7C0 4.23858 2.23858 2 5 2V5C5 6.65685 6.34315 8 8 8C9.65685 8 11 6.65685 11 5V2H27V5C27 6.65685 28.3431 8 30 8C31.6569 8 33 6.65685 33 5V2C35.7614 2 38 4.23858 38 7V31C38 33.7614 35.7614 36 33 36H5C2.23858 36 0 33.7614 0 31V7Z"
								fill="white"
							/>
							<path
								d="M6 2C6 0.89543 6.89543 0 8 0C9.10457 0 10 0.895431 10 2V5C10 6.10457 9.10457 7 8 7C6.89543 7 6 6.10457 6 5V2Z"
								fill="white"
							/>
							<path
								d="M28 2C28 0.89543 28.8954 0 30 0C31.1046 0 32 0.895431 32 2V5C32 6.10457 31.1046 7 30 7C28.8954 7 28 6.10457 28 5V2Z"
								fill="white"
							/>
							<path
								d="M3 11H35V30C35 31.6569 33.6569 33 32 33H6C4.34315 33 3 31.6569 3 30V11Z"
								fill="#283341"
							/>
							<text fill="white">
								<tspan x="25%" y="75%">
									{FILTERING_AGE_VALUE}
								</tspan>
							</text>
						</svg>
						<div css={tw`ml-2 md:ml-4 sm:text-base text-xs`}>{title}</div>
					</div>
				</div>
				<div css={tw`max-w-8 md:max-w-xs w-full inline-flex justify-between`}>
					<Button
						css={[
							css`
								color: white;
								:focus,
								:hover {
									background-color: #00ca86;
								}
								max-width: 154px;
								min-width: 50px;
								width: 100%;
								border-radius: 3px;
							`,

							tw`md:mr-0 mr-2 font-semibold`,

							value
								? css`
										background-color: #00ca86;
									`
								: css`
										border: 1px solid white;
									`,
						]}
						onClick={() => this.handleChange(true)}
						value="true"
						aria-label="yes"
						disableRipple
						disableFocusRipple
						disableElevation
					>
						Yes
					</Button>
					<Button
						css={[
							css`
								color: white;
								:focus,
								:hover {
									background-color: #149aff;
								}
								background-color: #283341;
								max-width: 154px;
								min-width: 50px;
								width: 100%;
								border-radius: 3px;
							`,

							!value
								? css`
										background-color: #149aff;
									`
								: css`
										border: 1px solid white;
									`,

							tw`font-semibold`,
						]}
						onClick={() => this.handleChange(false)}
						value="false"
						aria-label="No"
						disableRipple
						disableFocusRipple
						disableElevation
					>
						No
					</Button>
				</div>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(FilterBooleanButtonGroup);

FilterBooleanButtonGroup.propTypes = {
	filteringKey: PropTypes.string.isRequired,
	modalSelectedOption: PropTypes.any,
	title: PropTypes.string.isRequired,
	buildFilteringState: PropTypes.func.isRequired,
	isMobile: PropTypes.bool,
	deleteFilteringProperty: PropTypes.func.isRequired,
	setFilteringStateWithoutTransition: PropTypes.func.isRequired,
	vertical: PropTypes.string.isRequired,
};
