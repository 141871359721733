import OutboundLink from "@/components/general/OutboundLink";
// Import components
import withOutboundPaths from "@/components/HighOrderComponents/withOutboundPaths";
// import selectors
import { getCompanyName, getIsTargetedPath } from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export const CompanyBenefitsLinkWrapper = ({
	isCompanyReviewLinkable,
	companyReviewPath,
	companyVisitPath,
	company,
	companyIndex,
	children,
	nonTargetedClass = "",
	targetedClass = "",
	isVisitLinkingProhibited,
	isAllLinkingProhibited,
}) => {
	const isTargetedPath = useSelector(getIsTargetedPath);
	const companyName = useSelector((state) =>
		getCompanyName(state, { company }),
	);

	const nonTargetedLink = (
		<a
			className={nonTargetedClass}
			{...(isCompanyReviewLinkable ? { href: companyReviewPath } : {})}
			aria-label={companyName + " Review"}
		>
			{children}
		</a>
	);
	const targetedVisitLink = (
		<OutboundLink
			className={targetedClass}
			to={companyVisitPath}
			company={company}
		>
			{children}
		</OutboundLink>
	);
	const targetedAnchorPlaceholder = <a>{children}</a>;

	const targetedLink =
		isVisitLinkingProhibited || isAllLinkingProhibited
			? targetedAnchorPlaceholder
			: targetedVisitLink;

	return isTargetedPath ? targetedLink : nonTargetedLink;
};

CompanyBenefitsLinkWrapper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	companyIndex: PropTypes.number.isRequired,
	isCompanyReviewLinkable: PropTypes.bool.isRequired,
	companyReviewPath: PropTypes.string.isRequired,
	companyVisitPath: PropTypes.string.isRequired,
	company: PropTypes.object.isRequired,
	nonTargetedClass: PropTypes.string,
	targetedClass: PropTypes.string,
	isAllLinkingProhibited: PropTypes.bool.isRequired,
	isVisitLinkingProhibited: PropTypes.bool.isRequired,
};

export default withOutboundPaths(CompanyBenefitsLinkWrapper);
