import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";
import tw from "twin.macro";

import {
	BB_DOMAIN,
	BI_DOMAIN,
	BTR_DOMAIN,
	BW_DOMAIN,
	FVY_DOMAIN,
	IR_DOMAIN,
	SITE,
	TCR_DOMAIN,
} from "../../../config/constants";
import { FIXED_WIDTH_CARD_VERTICALS } from "../../../config/grid";
import { CREDIT_ASSISTANCE_PATH } from "../../../config/site/bestcreditreviews";
import {
	DISABILITY_INSURANCE_DEFAULT_PATH,
	IR_LIFE_INSURANCE_DEFAULT_PATH,
} from "../../../config/site/insuranceranked";
import {
	AUTO_REFINANCE_PATH,
	BUSINESS_LOANS_DEFAULT_PATH,
	PERSONAL_LOANS_DEFAULT_PATH,
} from "../../../config/site/thecreditreview";
import FilteringContainer from "../../containers/FilteringContainer";
import { useInnerWidth } from "../../hooks/useInnerWidth";
import { useVariations } from "../../hooks/useVariations";
import {
	getIsTransitionApplied,
	getUsesSimplifiedDesign,
	getVertical,
} from "../../selectors";
import { CompanyList } from "../CompanyList/CompanyList";
import FilteringModal from "../Modals/FilteringModal";
import MobileFilteringModal from "../Modals/MobileFilteringModal";
import MultiStepModal from "../Modals/MultiStep/MultiStepModal";
import MediaAlphaBanner from "./MediaAlphaBanner";

export const MainLayout = ({ deviceType }) => {
	useVariations();
	const innerWidth = useInnerWidth(deviceType);
	const isTransitionApplied = useSelector(getIsTransitionApplied);
	const transitionCss =
		isTransitionApplied === true ? styles.transition : undefined;

	const vertical = useSelector(getVertical);
	const usesSimplifiedDesign = useSelector(getUsesSimplifiedDesign);

	return (
		<div>
			<MediaAlphaBanner />
			<FilteringContainer innerWidth={innerWidth} />
			<div
				className={`container ${
					vertical === IR_LIFE_INSURANCE_DEFAULT_PATH ||
					DISABILITY_INSURANCE_DEFAULT_PATH
						? "lg2:tw-max-w-1170px"
						: ""
				} ${SITE === FVY_DOMAIN ? "tw-px-4 xl:tw-px-0" : ""} ${
					SITE === BTR_DOMAIN
						? "!tw-max-w-[500px] tw-px-4 lg:!tw-max-w-none xl:tw-px-0"
						: ""
				} ${
					vertical === CREDIT_ASSISTANCE_PATH || SITE === BW_DOMAIN
						? "tw-mx-auto tw-max-w-[1142px] tw-px-4 lg2:tw-max-w-[1142px] xl:tw-px-4"
						: ""
				} ${
					DOMAIN === BB_DOMAIN
						? "tw-mx-auto tw-w-[550px] tw-px-4 lg:tw-w-[1142px] lg:tw-max-w-none lg2:tw-w-[1142px]"
						: ""
				}`}
				css={[
					usesSimplifiedDesign && SITE !== FVY_DOMAIN && tw`!max-w-[1110px]`,
					path([SITE, vertical], styles),
					transitionCss,
				]}
			>
				<FilteringModal appElement={"app"} />
				<MobileFilteringModal appElement={"app"} />
				<MultiStepModal appElement={"app"} />
				<div
					className="col-xs-12 col-md-12 no-padding-right-sm no-padding-left-sm"
					css={[
						SITE === BI_DOMAIN && tw`flex justify-center items-center`,
						FIXED_WIDTH_CARD_VERTICALS.includes(vertical) &&
							tw`flex flex-col justify-center items-center`,
					]}
				>
					<CompanyList innerWidth={innerWidth} />
				</div>
			</div>
		</div>
	);
};

MainLayout.propTypes = {
	deviceType: PropTypes.string,
};

const styles = {
	transition: css`
		opacity: 0.5;
		transition: opacity 100ms ease-in-out;
	`,
	[IR_DOMAIN]: {
		[IR_LIFE_INSURANCE_DEFAULT_PATH]: tw`mt-10px`,
	},
	[TCR_DOMAIN]: {
		[AUTO_REFINANCE_PATH]: tw`md:mt-20px`,
		[BUSINESS_LOANS_DEFAULT_PATH]: tw`mt-0 md:mt-20px`,
		[PERSONAL_LOANS_DEFAULT_PATH]: tw`mt-0 md:mt-20px`,
	},
};
