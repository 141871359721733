import PropTypes from "prop-types";

export const BasePictureElement = ({ style, classNames, src, ...rest }) => {
	const hasContentfulSource =
		src && src.includes("images.ctfassets.net") && !src.includes("?");

	return (
		<picture css={style?.picture} className={classNames?.picture}>
			{hasContentfulSource ? (
				<>
					<source srcSet={`${src}?fm=avif&q=80`} type="image/avif" />
					<source srcSet={`${src}?fm=webp&q=80`} type="image/webp" />
				</>
			) : null}
			<img
				loading="lazy"
				decoding="async"
				css={style?.image}
				className={classNames?.image}
				{...rest}
			/>
		</picture>
	);
};

BasePictureElement.propTypes = {
	style: PropTypes.object,
	classNames: PropTypes.object,
	src: PropTypes.string,
};
