// import components
import OutboundLink from "@/components/general/OutboundLink";
import withOutboundPaths from "@/components/HighOrderComponents/withOutboundPaths";
// import constants
import { FVY_DOMAIN, SITE } from "@/config/constants";
import {
	MEAT_DELIVERY_SERVICES_PATH,
	PET_FOOD_DELIVERY_PATH,
	WINE_CLUBS_PATH,
} from "@/config/site/favy";
// import selectors
import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";
import tw, { css } from "twin.macro";

import Columns from "./Columns";
import SoftwareColumns from "./SoftwareColumns";

const styles = {
	columnLink: css`
		max-width: 355px;
		width: 100%;
		display: flex;
		align-items: center;

		@media (max-width: 1199px) {
			max-width: 305px;
		}

		@media (max-width: 991px) {
			max-width: 100%;
		}
	`,
	domainLevelOverrides: {
		[FVY_DOMAIN]: css`
			max-width: 300px;

			@media (max-width: 1024px) {
				max-width: 260px;
			}

			@media (max-width: 767px) {
				max-width: 82%;
			}

			@media (max-width: 450px) {
				max-width: 100%;
			}
		`,
		[WINE_CLUBS_PATH]: css`
			@media (max-width: 1024px) {
				max-width: 310px;
			}
		`,
		[MEAT_DELIVERY_SERVICES_PATH]: css`
			@media (min-width: 992px) {
				max-width: 355px;
			}
			@media (max-width: 991px) {
				max-width: 310px;
			}
		`,
		[PET_FOOD_DELIVERY_PATH]: css`
			@media (min-width: 1400px) {
				width: 295px;
			}

			@media (max-width: 1024px) {
				max-width: 300px;
			}
		`,
	},
	grid: {
		[2]: tw`grid grid-cols-2 items-center w-full`,

		[3]: tw`grid grid-cols-3 items-center w-full`,

		[4]: tw`grid grid-cols-2 sm:grid-cols-4 items-center w-full`,
	},
};

export const ServiceRightColumns = ({
	company,
	companyIndex,
	companyReviewPath,
	companyVisitPath,
	isCompanyReviewLinkable,
	isVisitLinkingProhibited,
	isAllLinkingProhibited,
}) => {
	const isTargetedPath = useSelector(selectors.getIsTargetedPath);
	const companyName = useSelector((state) =>
		selectors.getCompanyName(state, { company }),
	);
	const columnCount = useSelector((state) =>
		selectors.getServiceBlockColumnCount(state, { company }),
	);
	const preventRenderingColumns = useSelector(
		selectors.getPreventRenderingColumns,
	);
	const vertical = useSelector(selectors.getVertical);
	const useSoftwareDesign = useSelector(selectors.getUsesSoftwareDesign);
	const usesSimplifiedDesign = useSelector(selectors.getUsesSimplifiedDesign);

	const TargetedColumnLinkWrapper = ({ children }) =>
		isVisitLinkingProhibited || isAllLinkingProhibited ? (
			<a
				css={[
					useSoftwareDesign
						? tw`w-full`
						: [
								styles.columnLink,
								path(["domainLevelOverrides", SITE], styles),
								path(["domainLevelOverrides", vertical], styles),
							],
					usesSimplifiedDesign && tw`!hidden`,
				]}
				aria-label={companyName + " Review"}
			>
				{children}
			</a>
		) : (
			<OutboundLink
				css={[
					useSoftwareDesign
						? tw`w-full`
						: [
								styles.columnLink,
								path(["domainLevelOverrides", SITE], styles),
								path(["domainLevelOverrides", vertical], styles),
							],
					usesSimplifiedDesign && tw`!hidden`,
				]}
				company={company}
				to={companyVisitPath}
			>
				{children}
			</OutboundLink>
		);

	TargetedColumnLinkWrapper.propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node,
		]),
	};

	const NonTargetedColumnLinkWrapper = ({ children }) => (
		<a
			css={[
				useSoftwareDesign
					? tw`w-full`
					: [
							styles.columnLink,
							path(["domainLevelOverrides", SITE], styles),
							path(["domainLevelOverrides", vertical], styles),
						],
				usesSimplifiedDesign && tw`!hidden`,
			]}
			{...(isCompanyReviewLinkable ? { href: companyReviewPath } : {})}
			aria-label={companyName + " Review"}
		>
			{children}
		</a>
	);

	NonTargetedColumnLinkWrapper.propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node,
		]),
	};

	const ColumnLinkWrapper = ({ children }) =>
		isTargetedPath ? (
			<TargetedColumnLinkWrapper>
				<div css={useSoftwareDesign ? tw`w-full` : styles.grid[columnCount]}>
					{children}
				</div>
			</TargetedColumnLinkWrapper>
		) : (
			<NonTargetedColumnLinkWrapper>
				<div css={useSoftwareDesign ? tw`w-full` : styles.grid[columnCount]}>
					{children}
				</div>
			</NonTargetedColumnLinkWrapper>
		);

	ColumnLinkWrapper.propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node,
		]),
	};

	if (preventRenderingColumns) {
		return null;
	} else if (useSoftwareDesign) {
		return (
			<ColumnLinkWrapper>
				<SoftwareColumns company={company} />
			</ColumnLinkWrapper>
		);
	} else
		return (
			<ColumnLinkWrapper>
				<Columns company={company} />
			</ColumnLinkWrapper>
		);
};

ServiceRightColumns.propTypes = {
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
	companyReviewPath: PropTypes.string.isRequired,
	companyVisitPath: PropTypes.string.isRequired,
	isCompanyReviewLinkable: PropTypes.bool.isRequired,
	isAllLinkingProhibited: PropTypes.bool.isRequired,
	isVisitLinkingProhibited: PropTypes.bool.isRequired,
};

export default withOutboundPaths(ServiceRightColumns);
