import { MOBILE_WIDTH } from "@/config/constants";
import { getIsLocationCalloutRendered, getUserGeo } from "@/selectors";
import { css, keyframes } from "@emotion/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const LocationCallout = ({ forceRender }) => {
	const isRendered = useSelector(getIsLocationCalloutRendered);
	const geolocation = useSelector(getUserGeo);
	const isRenderable = (isRendered && geolocation) || forceRender;

	const animateWidth = keyframes`0% {
    opacity: 0%;
  }
  50% {
    opacity: 50%;
  }
  100% {
    opacity: 100%;
  }`;

	const styles = {
		wrapper: css`
			position: absolute;
			display: flex;
			flex-wrap: wrap;
			right: 0;
			@media (min-width: ${MOBILE_WIDTH}px) {
				height: 100%;
				top: 0;
			}
			animation: ${animateWidth} 0.5s ease-in-out;
			align-content: center;
		`,
		location: css`
			font-weight: 600;
		`,
		common: css`
			width: 100%;
			height: fit-content;
		`,
		state: css`
			text-transform: uppercase;
		`,
	};

	return isRenderable ? (
		<div css={styles.wrapper}>
			<div css={[styles.common, styles.location]}>Location:</div>
			<div css={[styles.common, styles.state]}>{geolocation}</div>
		</div>
	) : null;
};

export default LocationCallout;

LocationCallout.propTypes = {
	vertical: PropTypes.string.isRequired,
	forceRender: PropTypes.bool,
};
