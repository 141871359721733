import { useEffect, useRef } from "react";

/**
 * Returns a boolean that is `true` only on first render.
 */
export const useIsFirstRender = () => {
	const isFirstRender = useRef(true);

	useEffect(() => {
		isFirstRender.current = false;
	}, []);

	return isFirstRender.current;
};
