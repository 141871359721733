import PropTypes from "prop-types";

import useLinkProps from "../../hooks/useLinkProps";

const OutboundLink = (props) => {
	const { to, company, children, className, openInNewTab, onClick, ...rest } =
		props;
	const linkProps = useLinkProps({ to, company, onClick, openInNewTab });

	if (!linkProps.href) throw new Error("Href not provided to <OutboundLink>");

	return (
		<a {...rest} {...linkProps} className={className}>
			{children}
		</a>
	);
};

OutboundLink.propTypes = {
	to: PropTypes.string.isRequired,
	company: PropTypes.object,
	onClick: PropTypes.func,
	openInNewTab: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default OutboundLink;
