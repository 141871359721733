import { useCallback } from "react";
import { getVertical } from "@/selectors";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";

import { getGridLayoutForVertical } from "../../../config/grid";
import { useTargetedPageCompanies } from "../../hooks/useTargetedPageCompanies";
import ServiceBlockLayout from "../ServiceBlock/ServiceBlockLayout";
import { CompanyContext } from "./CompanyContext";

export const Companies = ({ companies, sliceStart, innerWidth, count }) => {
	const companiesToRender = companies.slice(sliceStart, sliceStart + count);
	const targetedCompanies = useTargetedPageCompanies(companiesToRender);
	const vertical = useSelector(getVertical);

	// TODO: need to double-check whether innerWidth is actually needed here as a dependency
	const renderGridLayout = useCallback(
		(CompanyComponent) =>
			targetedCompanies.map((company, idx) => (
				<CompanyContext.Provider
					key={path(["fields", "companyName"], company)}
					value={{ company, companyIndex: idx + sliceStart + 1 }}
				>
					<CompanyComponent />
				</CompanyContext.Provider>
			)),
		[companiesToRender, sliceStart, innerWidth],
	);

	const renderLegacyServiceBlock = targetedCompanies.map((company, idx) => (
		<ServiceBlockLayout
			key={path(["fields", "companyName"], company)}
			companyIndex={idx + sliceStart}
			innerWidth={innerWidth}
			company={company}
		/>
	));

	const gridLayout = getGridLayoutForVertical(vertical);
	return gridLayout ? renderGridLayout(gridLayout) : renderLegacyServiceBlock;
};

Companies.propTypes = {
	innerWidth: PropTypes.number.isRequired,
	sliceStart: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	companies: PropTypes.array.isRequired,
};
