import { useContext, useRef } from "react";
import { CompanyContext } from "@/components/CompanyList/CompanyContext";
import * as constants from "@/config/constants";
import { getIsNotUserFiltered } from "@/selectors";
import { ThemeContext } from "@emotion/react";
import { useSelector } from "react-redux";
import { Transition } from "react-transition-group";

import { RightArrowIcon } from "../../../client/assets/icons/common/RightArrowIcon";
import { CouponDiscountIcon } from "../../../psychicsreviews/assets/CouponDiscountIcon";
import BaseBenefitsList from "../../BaseComponents/BaseBenefitsList";
import { BaseCompanyLogo } from "../../BaseComponents/BaseCompanyLogo";
import BaseCompanyName from "../../BaseComponents/BaseCompanyName";
import { BaseCoupon } from "../../BaseComponents/BaseCoupon";
import { BaseFeaturedCompanyHeader } from "../../BaseComponents/BaseFeaturedCompanyHeader";
import BaseGridRatingHorizontal from "../../BaseComponents/BaseGridRatingHorizontal";
import BaseLinkWrapper from "../../BaseComponents/BaseLinkWrapper";
import BaseServiceFlag from "../../BaseComponents/BaseServiceFlag";
import { BaseSocialProof } from "../../BaseComponents/BaseSocialProof";
import { BaseVisitLink } from "../../BaseComponents/BaseVisitLink";
import { LeftIconPhoneLink } from "./LeftIconPhoneLink";
import { getStyles } from "./styles";

const DEFAULT_LINK_TEXT = "Visit Website";

export const GridLayoutWithPhoneCta = () => {
	const { companyIndex, isFeaturedCompany } = useContext(CompanyContext);
	const { isNotUserFiltered } = useSelector(getIsNotUserFiltered);
	const styles = getStyles({ isFeatured: isFeaturedCompany, companyIndex });
	const nodeRef = useRef(null);
	const { linkText = DEFAULT_LINK_TEXT } = useContext(ThemeContext);

	return (
		<Transition
			nodeRef={nodeRef}
			in
			appear={!isNotUserFiltered}
			timeout={constants.FILTERING_TRANSITION_DURATION}
			unmountOnExit
		>
			<div css={styles.layoutWrapper}>
				<BaseFeaturedCompanyHeader {...styles.featuredCompanyHeader} />
				<BaseLinkWrapper>
					<BaseCoupon {...styles.coupon} icon={<CouponDiscountIcon />} />
				</BaseLinkWrapper>
				<div css={styles.rootGrid.main}>
					<BaseServiceFlag style={styles.serviceFlag} />
					{/* Column 1 */}
					<div css={styles.col1}>
						<BaseLinkWrapper>
							<div css={styles.logoWrapper}>
								<BaseCompanyLogo style={styles.companyLogo} />
							</div>
						</BaseLinkWrapper>
					</div>
					{/* Column 2 */}
					<div css={styles.col2}>
						<BaseLinkWrapper>
							<div>
								<BaseCompanyName style={styles.companyName} />
							</div>
							<div>
								<BaseGridRatingHorizontal
									style={styles.rating}
									starSize={"small"}
								/>
							</div>
							<div>
								<BaseBenefitsList style={styles.benefits} />
							</div>
						</BaseLinkWrapper>
					</div>
					{/* Column 3 */}
					<div css={styles.col3}>
						<LeftIconPhoneLink className="tw-mx-auto tw-flex tw-max-w-[240px]" />
						<BaseLinkWrapper className="tw-w-full">
							<div className="tw-mx-auto tw-flex tw-max-w-[240px]">
								<BaseVisitLink {...styles.visitLink} icon={<RightArrowIcon />}>
									{linkText}
								</BaseVisitLink>
							</div>
						</BaseLinkWrapper>
						{companyIndex === 1 ? (
							<BaseLinkWrapper>
								<BaseSocialProof {...styles.socialProof} />
							</BaseLinkWrapper>
						) : null}
					</div>
				</div>
			</div>
		</Transition>
	);
};

export default GridLayoutWithPhoneCta;
