import { Icon } from "../../../../components/BaseComponents/Icon";

export const GiftBoxIcon = (props) => (
	<Icon viewBox="0 0 24 24" {...props}>
		<g clipPath="url(#clip0_24951_5102)">
			<path
				d="M23.25 5.4H19.083C19.3529 4.95608 19.4969 4.44979 19.5 3.93333C19.5 3.15536 19.1839 2.40926 18.6213 1.85915C18.0587 1.30905 17.2956 1 16.5 1C14.1525 1 12.75 2.74973 12 4.1702C11.25 2.74973 9.8475 1 7.5 1C6.70435 1 5.94129 1.30905 5.37868 1.85915C4.81607 2.40926 4.5 3.15536 4.5 3.93333C4.50311 4.44979 4.64706 4.95608 4.917 5.4H0.75C0.551088 5.4 0.360322 5.47726 0.21967 5.61479C0.0790176 5.75232 0 5.93884 0 6.13333L0 9.06667C0 9.26116 0.0790176 9.44769 0.21967 9.58521C0.360322 9.72274 0.551088 9.8 0.75 9.8H23.25C23.4489 9.8 23.6397 9.72274 23.7803 9.58521C23.921 9.44769 24 9.26116 24 9.06667V6.13333C24 5.93884 23.921 5.75232 23.7803 5.61479C23.6397 5.47726 23.4489 5.4 23.25 5.4ZM16.5 2.46667C16.8978 2.46667 17.2794 2.62119 17.5607 2.89624C17.842 3.1713 18 3.54435 18 3.93333C18 4.32232 17.842 4.69537 17.5607 4.97042C17.2794 5.24548 16.8978 5.4 16.5 5.4H13.062C13.5585 4.2604 14.6363 2.46667 16.5 2.46667ZM6 3.93333C6 3.54435 6.15804 3.1713 6.43934 2.89624C6.72064 2.62119 7.10218 2.46667 7.5 2.46667C9.354 2.46667 10.4355 4.2604 10.935 5.4H7.5C7.10218 5.4 6.72064 5.24548 6.43934 4.97042C6.15804 4.69537 6 4.32232 6 3.93333Z"
				fill="currentColor"
			/>
			<path
				d="M13.5 22.9999H18.75C19.5456 22.9999 20.3087 22.6909 20.8713 22.1408C21.4339 21.5907 21.75 20.8446 21.75 20.0666V11.2666H13.5V22.9999Z"
				fill="currentColor"
			/>
			<path
				d="M2.25 20.0666C2.25 20.8446 2.56607 21.5907 3.12868 22.1408C3.69129 22.6909 4.45435 22.9999 5.25 22.9999H10.5V11.2666H2.25V20.0666Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_24951_5102">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</Icon>
);
