import { useContext } from "react";
import { getIsTargetedPath } from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { useOutboundPaths } from "../../hooks/useOutboundPaths";
import { CompanyContext } from "../CompanyList/CompanyContext";
import OutboundLink from "../general/OutboundLink";

export const BaseReviewLink = ({ style, classNames, linkText }) => {
	const isTargetedPath = useSelector(getIsTargetedPath);
	if (isTargetedPath) return null;

	const { company } = useContext(CompanyContext);
	const { isCompanyReviewLinkable, companyReviewPath } = useOutboundPaths();
	if (!isCompanyReviewLinkable) return null;

	return (
		<OutboundLink
			to={companyReviewPath}
			company={company}
			css={style?.wrapper}
			className={classNames?.wrapper}
		>
			<span css={style?.text} className={classNames?.text}>
				{linkText}
			</span>
		</OutboundLink>
	);
};

export default BaseReviewLink;

BaseReviewLink.propTypes = {
	style: PropTypes.object,
	classNames: PropTypes.object,
	isCompanyReviewLinkable: PropTypes.bool,
	linkText: PropTypes.string,
	props: PropTypes.object,
};
