import { Component } from "react";
import { AGE_THRESHOLD } from "@/config/constants";
import { FILTERING_AGE_VALUE } from "@/config/site/insuranceranked";
import {
	getIsNotModalFiltered,
	getModalSelection,
	getVertical,
} from "@/selectors";
import {
	deleteFilteringProperty,
	setFilteringStateWithoutTransition,
} from "@/store/filtering/actions";
import { FormLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import tw, { css } from "twin.macro";

const FILTER_TOGGLE_BUTTON_GROUP_PREFIX = "FilterToggleButtonGroup";

const filterToggleButtonGroup_classes = {
	root: `${FILTER_TOGGLE_BUTTON_GROUP_PREFIX}-root`,
	grouped: `${FILTER_TOGGLE_BUTTON_GROUP_PREFIX}-grouped`,
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ value }) => {
	const baseRootStyles = {
		border: "none",
		borderRadius: "6px",
		height: "24px",
		width: "86px",
		marginRight: "10px",
	};

	return {
		[`&.${filterToggleButtonGroup_classes.root}`]:
			value === "true"
				? { backgroundColor: "#1774FF", ...baseRootStyles }
				: { backgroundColor: "#606E79", ...baseRootStyles },
		[`& .${filterToggleButtonGroup_classes.grouped}`]: {
			// groupStyles,
		},
	};
});

const FILTER_TOGGLE_BUTTON_PREFIX = "FilterToggleButtonGroup";

const filterToggleButton_classes = {
	root: `${FILTER_TOGGLE_BUTTON_PREFIX}-root`,
	selected: `${FILTER_TOGGLE_BUTTON_PREFIX}-selected`,
};

const StyledToggleButtonComponent = styled(ToggleButton)(() => ({
	[`&.${filterToggleButton_classes.root}`]: {
		width: "40px",
		border: "none",
		padding: "0px",
		marginTop: "2px",
		borderRadius: "5px !important",
		height: "20px",
		marginLeft: "2px !important",
		textTransform: "none",
		fontWeight: "600",
		color: "white",
	},
	[`&.${filterToggleButton_classes.selected}`]: {
		backgroundColor: "white !important",
		color: "black",
	},
}));

const StyledToggleButton = (props) => (
	<StyledToggleButtonComponent
		disableFocusRipple
		disableRipple
		classes={{
			root: filterToggleButton_classes.root,
			selected: filterToggleButton_classes.selected,
		}}
		{...props}
	/>
);

StyledToggleButton.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

const LabelComponent = (styles, label) => <span css={styles}>{label}</span>;

/* <----------------- End of local components -------------> */

const mapStateToProps = (state, ownProps) => ({
	modalSelectedOption: getModalSelection(state, ownProps),
	isModalFiltered: !getIsNotModalFiltered(state),
	vertical: getVertical(state),
});

const mapDispatchToProps = {
	deleteFilteringProperty,
	setFilteringStateWithoutTransition,
};

const styles = {
	filterToggleLabel: css`
		padding-left: 16px;
		color: #333333;
		font-weight: 500;
		font-family: unset;
		font-size: 14px;
	`,
};

export class FilterToggle extends Component {
	state = {
		value: false, // default to "No"
		valueChanged: false,
	};

	// @TODO this is somewhat opinionated in terms of implementation. Currently used for life insurance. Will need to be refactored once there's another use case.
	// event param not used as value is passed as second arg form Material UI toggle button group
	handleChange = (event, value) => {
		// deselecting a toggle button results in no value i.e. user clicks "Yes" to select it, then again to deselect it
		if (value) {
			// value param, when passed, will always be of type string and either "true" or "false". However, we need a boolean to determine how to set state and call actions.
			const stateValueCastAsBool = value === "true";
			// set local state with new value
			this.setState(
				{
					value: value,
					valueChanged: true,
				},
				() => {
					// if falsy (user selects "No") delete age threshold property from filtering store. This prevents it from being appended to visit links
					if (!stateValueCastAsBool) {
						this.props.deleteFilteringProperty(AGE_THRESHOLD);
					}
					/*
         if truthy (user selects "yes") add age threshold to filtering state.
         setFilteringStateWithoutTransition used to prevent applying transition twice, prevent issues with setTimeout used in 'setFilteringState' action
         */
					if (stateValueCastAsBool) {
						this.props.setFilteringStateWithoutTransition({
							[AGE_THRESHOLD]: FILTERING_AGE_VALUE,
						});
					}
					this.props.buildFilteringState(
						{ [this.props.filteringKey]: stateValueCastAsBool },
						this.props.isMobile,
					);
				},
			);
			// when user has deselected either 'Yes' or 'No'
		} else {
			// delete the age threshold property from redux filtering state
			this.props.deleteFilteringProperty(AGE_THRESHOLD);
			// set local state
			this.setState({
				value: false, // Default to false when nothing is selected
				valueChanged: true,
			});
			// update parent state (buildFilteringState calls setFilteringState action)
			this.props.buildFilteringState(
				{ [this.props.filteringKey]: false },
				this.props.isMobile,
			);
		}
	};

	stringifyIfDefined = (val) => {
		if (val === undefined) {
			return val;
		} else return val.toString();
	};

	render() {
		const { title, modalSelectedOption, isModalFiltered } = this.props;
		const { value, valueChanged } = this.state;

		const modalValue = this.stringifyIfDefined(modalSelectedOption);
		const stateValue = this.stringifyIfDefined(value);

		// use modalValue if modalFiltered and user didn't make a change in the UI
		const toggleButtonValue =
			isModalFiltered && !valueChanged ? modalValue : stateValue;

		const Label = () => LabelComponent(styles.filterToggleLabel, title);

		return (
			<div css={tw`w-full inline-flex items-center justify-between`}>
				<FormLabel component={Label} />
				<StyledToggleButtonGroup
					classes={{
						root: filterToggleButtonGroup_classes.root,
						grouped: filterToggleButtonGroup_classes.grouped,
					}}
					value={toggleButtonValue}
					exclusive
					onChange={this.handleChange}
				>
					<StyledToggleButton value="true" aria-label="yes">
						Yes
					</StyledToggleButton>
					<StyledToggleButton value="false" aria-label="no">
						No
					</StyledToggleButton>
				</StyledToggleButtonGroup>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterToggle);

FilterToggle.propTypes = {
	filteringKey: PropTypes.string.isRequired,
	modalSelectedOption: PropTypes.any,
	title: PropTypes.string.isRequired,
	buildFilteringState: PropTypes.func.isRequired,
	isMobile: PropTypes.bool,
	isModalFiltered: PropTypes.bool.isRequired,
	deleteFilteringProperty: PropTypes.func.isRequired,
	setFilteringStateWithoutTransition: PropTypes.func.isRequired,
	vertical: PropTypes.string.isRequired,
};
