import { useContext } from "react";
import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { CompanyContext } from "../CompanyList/CompanyContext";

const BaseAwardText = ({ style, className }) => {
	const { company } = useContext(CompanyContext);
	const awardText = useSelector((state) =>
		selectors.getAwardText(state, { company }),
	);
	if (!awardText) return null;

	return (
		<div css={style} className={className}>
			{awardText}
		</div>
	);
};

export default BaseAwardText;

BaseAwardText.propTypes = {
	style: PropTypes.object,
	className: PropTypes.string,
};
