// import constants
import { FVY_DOMAIN, IR_DOMAIN, SITE, TCR_DOMAIN } from "@/config/constants";
import {
	CELL_PHONE_PLANS_PATH,
	COFFEE_CLUBS_PATH,
	FLOWER_DELIVERY_PATH,
	SOLAR_PATH,
	STREAMING_SERVICES_PATH,
} from "@/config/site/favy";
import * as irConstants from "@/config/site/insuranceranked";
import * as tcrConstants from "@/config/site/thecreditreview";
// import selectors
import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";
import tw, { css } from "twin.macro";

const amBestRatingColorMap = {
	"A++": css`
		color: #2b8da7;
	`,
	"A+": css`
		color: #2b8da7;
	`,
	"A-": css`
		color: #2b8da7;
	`,
	A: css`
		color: #2b8da7;
	`,
	"B+": css`
		color: #16c661;
	`,
	B: css`
		color: #16c661;
	`,
	"B-": css`
		color: #16c661;
	`,
	"C+": css`
		color: #ecbb15;
	`,
	C: css`
		color: #ecbb15;
	`,
	"C-": css`
		color: #ecbb15;
	`,
	"D+": css`
		color: #f16338;
	`,
	D: css`
		color: #f16338;
	`,
	"D-": css`
		color: #f16338;
	`,
	F: css`
		color: #c70000;
	`,
};

const styles = {
	columnElement: {
		baseStyles: css`
			font-weight: 600;
			font-size: 16px;
			color: #232227;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			width: 100%;
			text-align: center;
			height: 100% @media (max-width: 1199px) {
				font-size: 14px;
			}

			@media (max-width: 991px) {
				font-size: 16px;
				flex-direction: column;
				padding: 0 0 1.25rem 0;
			}

			@media (max-width: 580px) {
				font-size: 13px;
			}
		`,
		[FVY_DOMAIN]: {
			[COFFEE_CLUBS_PATH]: css`${tw`text-xl`} @media (max-width: 580px) {${tw`text-xl`}`,
		},
	},
	/* For styling the <div> that surrounds the column content and label*/
	contentWrapper: {
		[2]: [
			css`
				@media (max-width: 580px) {
					max-width: 100px;
				}
			`,
			tw`w-full flex flex-col justify-between`,
		],
		[3]: [
			css`
				@media (max-width: 991px) {
					padding: 0 5px 0 5px;
				}
			`,
			tw`w-full flex flex-col justify-between`,
		],
		[4]: css`
			@media (max-width: 580px) {
				max-width: 150px;
			}
		`,
	},
	/* For styling the column/field value*/
	columnContent: {
		[TCR_DOMAIN]: {
			[tcrConstants.BUSINESS_LOANS_DEFAULT_PATH]: {
				[2]: css`
					background-color: #0e7c99;
					text-align: center;
					color: #fff;
					padding: 4px 20px;
					border-radius: 15px;
					max-width: 60px;
					margin: auto;
				`,
			},
			[tcrConstants.AUTO_REFINANCE_PATH]: {
				[0]: css`
					@media (max-width: 640px) {
						font-size: 16px;
					}
				`,
				[1]: css`
					@media (max-width: 640px) {
						font-size: 16px;
					}
				`,
				[2]: css`
					@media (max-width: 640px) {
						font-size: 16px;
					}
				`,
			},
			[tcrConstants.BOOKKEEPING_PATH]: {
				[1]: amBestRatingColorMap,
			},
		},
		[IR_DOMAIN]: {
			[irConstants.IR_LIFE_INSURANCE_DEFAULT_PATH]: {
				[2]: amBestRatingColorMap,
			},
			[irConstants.IR_HEALTH_INSURANCE_DEFAULT_PATH]: {
				[2]: amBestRatingColorMap,
			},
			[irConstants.DISABILITY_INSURANCE_DEFAULT_PATH]: {
				[1]: amBestRatingColorMap,
			},
		},
		[FVY_DOMAIN]: {
			[SOLAR_PATH]: {
				[1]: amBestRatingColorMap,
			},
		},
	},
	/* For styling the label of the column*/
	columnLabel: {
		base: css`
			display: none;
			font-family: Montserrat;
			font-weight: 600;
			font-size: 11px;
			color: #787e81;
			text-transform: uppercase;
			letter-spacing: 0.08em;
			padding-bottom: 5px;
			width: 100%;
			height: auto;

			@media (max-width: 991px) {
				display: block;
			}
		`,
		override: css`
			display: block !important;
		`,
		[IR_DOMAIN]: css`
			font-family: Graphik Web;
		`,
		[2]: css`
			@media (max-width: 700px) {
				letter-spacing: 0.05em;
			}
		`,
		[3]: css`
			@media (max-width: 580px) {
				letter-spacing: 0.05em;
			}
		`,
		[4]: css`
			@media (max-width: 700px) {
				letter-spacing: 0.05em;
				max-width: 150px;
			}
		`,
	},
};

function generateColumns(
	columnInformation,
	columnCount,
	styles,
	vertical,
	company,
) {
	const ColumnChildElements = ({ column, idx }) => {
		const columnValue = column.value;
		const isStringColumnValue = typeof columnValue === "string";
		const isMultiLine = isStringColumnValue
			? columnValue.includes("\\n")
			: false;
		const productDescription = useSelector((state) =>
			selectors.getProductDescription(state, { company }),
		);
		const usesSoftwareDesign = useSelector(selectors.getUsesSoftwareDesign);
		const labelOverride =
			productDescription && !usesSoftwareDesign ? "override" : null;

		return (
			<div css={[path(["contentWrapper", columnCount], styles)]}>
				<div
					css={[
						styles.columnLabel.base,
						path(["columnLabel", SITE], styles),
						path(["columnLabel", columnCount], styles),
						path(["columnLabel", labelOverride], styles),
						[
							FLOWER_DELIVERY_PATH,
							STREAMING_SERVICES_PATH,
							CELL_PHONE_PLANS_PATH,
						].includes(vertical) && tw`block max-w-[100px] mx-auto`,
					]}
				>
					{column.label}
				</div>
				{isMultiLine ? (
					columnValue.split("\\n").map((multilineColVal, line) => (
						<div
							key={line}
							css={[
								path(["columnContent", SITE, vertical, idx], styles),
								path(
									["columnContent", SITE, vertical, idx, multilineColVal],
									styles,
								),
							]}
						>
							{multilineColVal}
						</div>
					))
				) : (
					<div
						key={idx}
						css={[
							path(["columnContent", SITE, vertical, idx], styles),
							path(["columnContent", SITE, vertical, idx, columnValue], styles),
						]}
					>
						{columnValue}
					</div>
				)}
			</div>
		);
	};

	ColumnChildElements.propTypes = {
		column: PropTypes.object.isRequired,
		idx: PropTypes.number.isRequired,
	};

	const ThreeOrLessColumns = () =>
		columnInformation.map((column, idx) => (
			<div
				key={idx}
				css={[
					styles.columnElement.baseStyles,
					path(["columnElement", SITE, vertical], styles),
					tw`content-center h-full`,
				]}
			>
				<ColumnChildElements column={column} idx={idx} />
			</div>
		));

	const FourColumns = () => {
		const DesktopColumns = () =>
			columnInformation.map((column, idx) => (
				<div
					key={idx}
					css={[
						styles.columnElement.baseStyles,
						path(["columnElement", SITE, vertical], styles),
						tw`content-center hidden sm:flex`,
					]}
				>
					<ColumnChildElements column={column} idx={idx} />
				</div>
			));

		const MobileColumns = () => {
			// eslint-disable-next-line
			const columnOneInfo = columnInformation.slice(0, 2);
			// eslint-disable-next-line
			const columnTwoInfo = columnInformation.slice(2);

			const ColumnOne = () =>
				columnOneInfo.map((column, idx) => (
					<div key={idx} css={tw`sm:hidden`}>
						<div
							css={[
								styles.columnElement.baseStyles,
								path(["columnElement", SITE, vertical], styles),
								tw`content-center`,
							]}
						>
							<ColumnChildElements column={column} idx={idx} />
						</div>
					</div>
				));

			const ColumnTwo = () =>
				columnTwoInfo.map((column, idx) => (
					<div key={idx} css={tw`sm:hidden`}>
						<div
							css={[
								styles.columnElement.baseStyles,
								path(["columnElement", SITE, vertical], styles),
								tw`content-center`,
							]}
						>
							{/* eslint-disable-next-line*/}
							<ColumnChildElements column={column} idx={idx + 2} />
						</div>
					</div>
				));

			return (
				<>
					<ColumnOne />
					<ColumnTwo />
				</>
			);
		};

		return (
			<>
				<DesktopColumns />
				<MobileColumns />
			</>
		);
	};

	// eslint-disable-next-line
	return columnCount <= 3 ? <ThreeOrLessColumns /> : <FourColumns />;
}

export const Columns = (props) => {
	const vertical = useSelector(selectors.getVertical);
	const columnInformation = useSelector((state) =>
		selectors.getColumnInformation(state, props),
	);
	const { company } = props;
	const columnCount = useSelector((state) =>
		selectors.getServiceBlockColumnCount(state, { company }),
	);

	return columnInformation
		? generateColumns(columnInformation, columnCount, styles, vertical, company)
		: null;
};

Columns.propTypes = {
	company: PropTypes.object.isRequired,
};

export default Columns;
