import { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import tw from "twin.macro";

import {
	BI_DOMAIN,
	BW_DOMAIN,
	FVY_DOMAIN,
	IR_DOMAIN,
	SITE,
} from "../../../config/constants";
import { FIXED_WIDTH_CARD_VERTICALS } from "../../../config/grid";
import { CREDIT_ASSISTANCE_PATH } from "../../../config/site/bestcreditreviews";
import {
	DISABILITY_INSURANCE_DEFAULT_PATH,
	FINAL_EXPENSE_INSURANCE_PATH,
	IR_AUTO_INSURANCE_DEFAULT_PATH,
	IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH,
	IR_LIFE_INSURANCE_DEFAULT_PATH,
} from "../../../config/site/insuranceranked";
import { Pagination } from "../../components/CompanyList/Pagination";
import { NoResults } from "../../components/general/NoResults";
import {
	replacePhoneNumbers,
	returnPaginationInformation,
} from "../../helpers";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import {
	getCategoryName,
	getIsTargetedPath,
	getVertical,
	sortedCompaniesSelector,
} from "../../selectors";
import { BackgroundSearchWidget } from "../UIComponents/BackgroundSearchWidget";
import { Companies } from "./Companies";
import { CompanyListHeader } from "./CompanyListHeader";
import { FeaturedCompany } from "./FeaturedCompany";
import { PaginationWrapper } from "./PaginationWrapper";

const transformHyphenatedString = (inputString) => {
	const words = inputString.split("-");
	const capitalizedWords = words.map(
		(word) => word.charAt(0).toUpperCase() + word.slice(1),
	);
	return capitalizedWords.join(" ");
};

export const CompanyList = ({ innerWidth }) => {
	const location = useLocation();
	const vertical = useSelector(getVertical);
	const isTargetedPath = useSelector(getIsTargetedPath);
	const companies = useSelector(sortedCompaniesSelector);
	const categoryName = useSelector(getCategoryName);
	const {
		count,
		partialQueryObjectForPaginationClicks,
		isInvalidQuery,
		page,
		redirectLocationSearch,
	} = returnPaginationInformation(location, vertical, isTargetedPath);

	useEffect(() => {
		replacePhoneNumbers();
	}, []);

	useUpdateEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

		replacePhoneNumbers();
	}, [page]);

	if (isInvalidQuery) {
		return <Navigate to={`${location.pathname}?${redirectLocationSearch}`} />;
	}

	if (companies.length === 0) {
		return <NoResults categoryName={categoryName} />;
	}

	return (
		<div
			id="companyList"
			aria-label={`The Best ${transformHyphenatedString(vertical)} Companies of ${new Date().getFullYear()}`}
			css={[
				SITE === IR_DOMAIN && tw`pb-8 desktop:pt-8`,
				vertical === DISABILITY_INSURANCE_DEFAULT_PATH && tw`desktop:pt-2`,
				vertical === IR_LIFE_INSURANCE_DEFAULT_PATH &&
					tw`pt-0 pb-12 desktop:pt-0`,
				(vertical === IR_AUTO_INSURANCE_DEFAULT_PATH ||
					vertical === FINAL_EXPENSE_INSURANCE_PATH ||
					vertical === IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH) &&
					tw`desktop:pt-2`,
				SITE === BW_DOMAIN && tw`pb-8`,
				SITE === BW_DOMAIN && tw`flex flex-col items-center`,
				SITE === BI_DOMAIN && tw`py-8 max-w-[600px] lg:max-w-none`,
				SITE === FVY_DOMAIN && tw`w-full lg2:min-w-[1100px]`,
				FIXED_WIDTH_CARD_VERTICALS.includes(vertical) &&
					tw`max-w-[550px] lg:max-w-none min-w-min sm:min-w-[550px] lg:min-w-[900px] lg2:min-w-[1100px]`,
				vertical === CREDIT_ASSISTANCE_PATH && tw`mx-auto w-full`,
			]}
		>
			<CompanyListHeader />
			<BackgroundSearchWidget companies={companies} />
			<Companies
				companies={companies}
				sliceStart={page === 1 ? 0 : (page - 1) * count}
				innerWidth={innerWidth}
				count={count}
			/>
			<PaginationWrapper numberOfCompaniesPerPage={count}>
				<Pagination
					page={page}
					count={count}
					partialQueryObjectForPaginationClicks={
						partialQueryObjectForPaginationClicks
					}
					pageCount={Math.ceil(companies.length / count)}
				/>
			</PaginationWrapper>
			<FeaturedCompany companies={companies} innerWidth={innerWidth} />
		</div>
	);
};

CompanyList.propTypes = {
	innerWidth: PropTypes.number.isRequired,
};
